// src/layouts/AppLayout.tsx
import React, { useCallback, useEffect, useState } from "react";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DashboardOutlined,
  SettingOutlined,
  KeyOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  LayoutOutlined,
  CopyOutlined,
  IdcardOutlined,
  ShoppingCartOutlined,
  FundOutlined,
  BoxPlotOutlined,
  SubnodeOutlined,
  UserOutlined,
  PrinterOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import { FaUserCircle, FaSignOutAlt } from "react-icons/fa";
import logout from "../utils/logout";

import type { MenuProps } from "antd";
import { GrRevert } from "react-icons/gr";
import {
  Alert,
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  message,
  Select,
  Tag,
  theme,
} from "antd";
import { throttle } from "throttle-debounce";
import { Toaster } from "react-hot-toast";
import qz from "qz-tray";
import KJUR, { KEYUTIL, stob64, hextorstr } from "jsrsasign";
import { Buffer } from "buffer";
import { initialize, terminate } from "../services/config/progress";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import * as labelPrinterActions from "../store/labelPrinterSlice";
import * as warehouseActions from "../store/warehouseSlice";
import * as purchaseWarehouseActions from "../store/purchaseWarehouseSlice";
import * as settingActions from "../store/settingSlice";
import * as batchActions from "../store/batchSlice";
import * as skuActions from "../store/skuSlice";
import * as poActions from "../store/poSlice";
import * as clientActions from "../store/clientSlice";
import * as authActions from "../store/authSlice";
import { Store } from "../store";
import { TbCreditCardPay } from "react-icons/tb";
import { LuArrowLeftRight } from "react-icons/lu";
import Dashboard from "../views/Dashboard";
import Setting from "../views/Setting";
import Skus from "../views/Sku";
import Orders from "../views/Order";
import Invoices from "../views/Invoice";
import CreditNote from "../views/CreditNote";
import Vendors from "../views/Vendor";
import Suppliers from "../views/Supplier";
import Reports from "../views/Report";
import ManualInward from "../views/ManualInward";
import PurchaseOrders from "../views/PurchaseOrder";
import RawMaterialPO from "../views/RawMaterialPO";
import Payments from "../views/Payment";
import Ledgers from "../views/Ledger";
import Journals from "../views/Journal";
import VendorCreditNotes from "../views/VendorCreditNote";
import VendorDebitNotes from "../views/VendorDebitNote";
import GoodsReceiveNotes from "../views/GoodsReceiveNote";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { TiDocumentAdd } from "react-icons/ti";
import dayjs from "dayjs";
import Logo from "../components/Logo";
import { MdOutlineSummarize, MdChecklist } from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import { BsDownload } from "react-icons/bs";
import useAllowedModules from "../hooks/useAllowedModules";
import useMenuItems from "../hooks/useMenuItems";
import { useDebounceEffect } from "ahooks";

type MenuItem = Required<MenuProps>["items"][number];
const { Header, Content, Sider } = Layout;
let isConnecting = false;

const cert = Buffer.from(
  process.env.REACT_APP_QZ_TRAY_CERT || "",
  "base64"
).toString("ascii");
const key = Buffer.from(
  process.env.REACT_APP_QZ_TRAY_PRIVATE_KEY || "",
  "base64"
).toString("ascii");

const handleConnect = () => {
  return new Promise<void>((resolve, reject) => {
    console.log("Connecting...");
    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        try {
          var pk = KEYUTIL.getKey(key);
          var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
          sig.init(pk);
          sig.updateString(toSign);
          var hex = sig.sign();
          // console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
          resolve(stob64(hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject(err);
          message.error("Error connecting to QZ Tray");
        }
      };
    });
    qz.security.setCertificatePromise(function (resolve, reject) {
      resolve(cert);
    });
    qz.websocket
      .connect()
      .then(function () {
        console.log("Connected");
        message.success("Successfully connected to QZ Tray!");
        resolve();
      })
      .catch((err) => {
        console.log(err);
        reject(err);
        message.error(err);
      });
  });
};

const AppLayout: React.FC = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state: Store) => state.auth);
  const clientState = useSelector((state: Store) => state.client);
  const batchState = useSelector((state: Store) => state.batch);
  const navigate = useNavigate();
  const settingsState = useSelector((state: Store) => state.setting);
  const labelPrinterState = useSelector((state: Store) => state.labelPrinter);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const role = authState.role;
  const menuItems = useMenuItems();

  // Use the custom hook to get allowedModules
  const allowedModules = useAllowedModules(role?.permissions || {}, menuItems);

  const handleNavigate = (keyPath: string[]) => {
    const [key, childKey] = keyPath;
    let selectedMenu: any = allowedModules.find((item) => item?.key === key);
    if (selectedMenu && selectedMenu.link) {
      return navigate(selectedMenu.link);
    }
    selectedMenu = (
      allowedModules.find((item: any) => item.key === childKey) as any
    )?.children?.find((item: any) => item.key === key);
    if (selectedMenu && selectedMenu.link) {
      return navigate(selectedMenu.link);
    }
  };

  const handleGlobalLoadDebounced = useCallback(
    throttle(
      2000,
      () => {
        console.log("Global Load");
        dispatch(settingActions.getSelectedApiKeyAsync({}));
        dispatch(clientActions.loadClientsAsync({}));
        dispatch(skuActions.getUnmappedSkusAsync({}));
      },
      { noTrailing: true }
    ),
    []
  );

  useDebounceEffect(
    () => {
      dispatch(poActions.fetchProductsForClient());
      dispatch(poActions.fetchVendors());
      dispatch(poActions.fetchSuppliers());
      dispatch(settingActions.loadZonesAsync({}));
    },
    [clientState.current, dispatch],
    { wait: 2000 }
  );

  const handleClientLoadDebounced = useCallback(
    throttle(
      2000,
      () => {
        console.log("Client Load");
        dispatch(
          warehouseActions.loadWarehousesForClientAsync(clientState.current)
        );
        dispatch(batchActions.loadBatchesAsync(clientState.current));
      },
      { noTrailing: true }
    ),
    [clientState.current, dispatch]
  );

  useEffect(() => {
    const accessibleClients = authState.user?.clients || [];
    if (clientState.clients?.length > 0) {
      setTimeout(() => {
        const client = clientState.clients.find((client) =>
          accessibleClients.includes(client._id)
        );
        if (!clientState.current && client) {
          dispatch(clientActions.setCurrent(client._id));
        }
      }, 1000);
    }
  }, [clientState.current, authState.user, clientState.clients, dispatch]);

  useEffect(() => {
    handleGlobalLoadDebounced();

    // QZ TRAY
    try {
      if (!qz.websocket.isActive() && !isConnecting) {
        handleConnect()
          .then(() => qz.printers.find())
          .then((printers) => {
            dispatch(labelPrinterActions.setLabelPrinters(printers));
            dispatch(settingActions.setConnectedToQzTray(true));
            const defaultPrinter = localStorage.getItem("defaultPrinter");
            if (
              printers.includes(defaultPrinter || "") &&
              !labelPrinterState.labelPrinter
            ) {
              dispatch(
                labelPrinterActions.setLabelPrinter(defaultPrinter || "")
              );
              message.success("Configured QZ Tray Printer");
            }
          })
          .catch((err) => {
            console.error(err);
            // Optionally handle error
          });
        isConnecting = true;
      }
    } catch (error) {
      console.error(error);
      dispatch(settingActions.setConnectedToQzTray(false));
    }
  }, [handleGlobalLoadDebounced, dispatch, labelPrinterState.labelPrinter]);

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (clientState.current && clientState.clients?.length > 0) {
      handleClientLoadDebounced();
    }
  }, [clientState.current, clientState.clients, handleClientLoadDebounced]);

  // const dispatchLoadSkusThrottled = useCallback(
  //   throttle(5000, () => dispatch(skuActions.loadSkusAsync({})), {
  //     noTrailing: true,
  //   }),
  //   [dispatch]
  // );

  // useEffect(() => {
  //   dispatchLoadSkusThrottled();
  // }, [batchState.batches, dispatchLoadSkusThrottled]);

  useEffect(() => {
    dispatch(authActions.getUserAsync());
  }, [dispatch]);

  useEffect(() => {
    initialize();
    return () => {
      terminate();
    };
  }, [authState.user]);

  const AccountMenu = (
    <Menu>
      <Menu.Item key="0" icon={<FaUserCircle />} className="flex items-center">
        <span className="ml-2">Account</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="3"
        icon={<FaSignOutAlt />}
        className="flex items-center"
        onClick={handleLogout}
      >
        <span className="ml-2">Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="min-h-screen">
      <Sider collapsible collapsed={collapsed} theme="light">
        <div className="flex justify-center my-6">
          <Logo height="60" />
        </div>
        <Menu
          onClick={(e) => {
            handleNavigate(e.keyPath);
          }}
          theme="light"
          mode="inline"
          defaultSelectedKeys={["dashboard"]}
          items={allowedModules}
        />
      </Sider>
      <Layout>
        <Header
          style={{ background: colorBgContainer }}
          className="flex justify-between items-center pl-0 pr-4"
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <div className="flex gap-x-2 items-baseline">
            {batchState.batches.length > 0 ? (
              batchState.batches[0].status === "Ended" ? (
                <Tag
                  bordered={false}
                  icon={<CheckCircleOutlined />}
                  color="success"
                >
                  Latest Batch Completed Successfully on{" "}
                  {dayjs(batchState.batches[0].endedAt).format(
                    "DD/MM/YY hh:mm A"
                  )}
                </Tag>
              ) : !["Errored", "Ended"].includes(
                  batchState.batches[0].status
                ) ? (
                <Tag
                  bordered={false}
                  icon={<LoadingOutlined />}
                  color="processing"
                >
                  Latest Batch Started At{" "}
                  {dayjs(batchState.batches[0].createdAt).format(
                    "DD/MM/YY hh:mm A"
                  )}{" "}
                  Running...
                </Tag>
              ) : (
                <Tag
                  bordered={false}
                  icon={<InfoCircleOutlined />}
                  color="error"
                >
                  Latest Batch Failed at{" "}
                  {
                    batchState.batches[0].timeline[
                      batchState.batches[0].timeline.length - 1
                    ].type
                  }{" "}
                  Stage
                </Tag>
              )
            ) : (
              <></>
            )}
            <div className="flex gap-x-2 items-baseline ">
              <div>Client: </div>
              <Select
                size="small"
                allowClear={false}
                variant="borderless"
                placeholder="Select Warehouse"
                loading={clientState.loading}
                value={clientState.current}
                onChange={(value) => dispatch(clientActions.setCurrent(value))}
                status={!clientState.current ? "error" : ""}
                style={{ width: 300 }}
              >
                {clientState.clients
                  .filter((client) =>
                    authState.user?.clients.includes(client._id)
                  )
                  .map((client) => (
                    <Select.Option value={client._id} key={client._id}>
                      {client.name} ({client.id})
                    </Select.Option>
                  ))}
              </Select>
              {!clientState.current && (
                <Alert
                  type="error"
                  showIcon
                  message={`No Warehouse Selected`}
                />
              )}
            </div>
            <div>
              <Dropdown overlay={AccountMenu} trigger={["click"]}>
                <div className="flex items-center cursor-pointer">
                  <Avatar icon={<UserOutlined />} />
                  <span className="ml-2 capitalize">
                    {authState?.user?.name}
                  </span>
                </div>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "16px 16px",
            minHeight: 280,
          }}
        >
          <div className=" px-4 py-3">
            <Routes>
              <Route path="dashboard/*" element={<Dashboard />} />
              <Route path="skus/*" element={<Skus />} />
              <Route path="invoices/*" element={<Invoices />} />
              <Route path="orders/*" element={<Orders />} />
              <Route path="returns/*" element={<CreditNote />} />
              <Route path="vendors/*" element={<Vendors />} />
              <Route path="suppliers/*" element={<Suppliers />} />
              <Route path="settings/*" element={<Setting />} />
              <Route path="finance/payments/*" element={<Payments />} />
              <Route path="finance/ledgers/*" element={<Ledgers />} />
              <Route
                path="purchases/purchase-orders/*"
                element={<PurchaseOrders />}
              />
              <Route
                path="raw-material-purchase-orders/*"
                element={<RawMaterialPO />}
              />
              <Route path="inward/*" element={<ManualInward />} />
              <Route path="reports/*" element={<Reports />} />
              <Route
                path="finance/vendor-credit-note/*"
                element={<VendorCreditNotes />}
              />
              <Route
                path="finance/vendor-debit-note/*"
                element={<VendorDebitNotes />}
              />
              <Route
                path="purchases/purchase-orders/goods-receive-notes/*"
                element={<GoodsReceiveNotes />}
              />
              <Route path="finance/journal/*" element={<Journals />} />
            </Routes>
          </div>
        </Content>
      </Layout>
      <Toaster />
    </Layout>
  );
};

export default AppLayout;
