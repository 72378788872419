import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/sku";

export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);
export const getLatestStock = () => getRequest(`${SERVICE_URL}/stock/latest`);
export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const getAllForAllClients = () =>
  getRequest(`${SERVICE_URL}/client/all`);
export const get = (id: string) => getRequest(`${SERVICE_URL}/${id}`);
export const getByIds = (ids: string[]) =>
  postRequest(`${SERVICE_URL}/ids`, ids);
export const getbySkuCodes = (ids: string[]) =>
  postRequest(`${SERVICE_URL}/sku-codes`, { skuCodes: ids });
export const getAllProducts = () => getRequest(`${SERVICE_URL}/products`);
export const getAllProductsForClient = () =>
  getRequest(`${SERVICE_URL}/client/products`);
export const getUnmappedSkus = () => getRequest(`${SERVICE_URL}/unmapped`);
export const getMappingForSku = (sku: string) =>
  getRequest(`${SERVICE_URL}/mapping/${sku}`);
export const exportToExcel = () => postBlobRequest(`${SERVICE_URL}/export`);
export const getSkuReport = () => getRequest(`${SERVICE_URL}/report`);
export const getStockBySkuCodes = (skuCodes: string[]) =>
  postRequest(`${SERVICE_URL}/latest-stock-by-skus`, { skuCodes });
export default {
  paginate,
  getUnmappedSkus,
  getAll,
  getLatestStock,
  getAllProducts,
  getMappingForSku,
  exportToExcel,
  getAllProductsForClient,
  get,
  getbySkuCodes,
  getByIds,
  getAllForAllClients,
  getSkuReport,
  getStockBySkuCodes,
};
