import { useDispatch, useSelector } from "react-redux";
import { Store } from "../store";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  List,
  Select,
  Space,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import batchService from "../services/batch";
import statesOfIndia from "../utils/statesOfIndia";
import * as reportActions from "../store/reportSlice";
import * as settingActions from "../store/settingSlice";
import * as warehouseActions from "../store/warehouseSlice";
import * as XLSX from "xlsx";
import { InfoCircleOutlined } from "@ant-design/icons";
import OMSWarehouseSelect from "../components/OMSWarehouseSelect";
import rwpoService from "../services/rwpo";
import _ from "lodash";
import { useThrottleEffect } from "ahooks";
const API_KEY_ERRORS = {
  NO_ACTIVE_KEY: "No Active Key Found",
  ACTIVE_KEY_IS_INVALID: "Active Key is Invalid",
};

const API_KEY_WARNINGS = {
  KEY_EXPIRING: "Active Key is Expiring Soon",
};
const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Tab 1",
    children: "Content of Tab Pane 1",
  },
  {
    key: "2",
    label: "Tab 2",
    children: "Content of Tab Pane 2",
  },
  {
    key: "3",
    label: "Tab 3",
    children: "Content of Tab Pane 3",
  },
];

function GlobalDashboard(props: any) {
  const { reloadKey } = props;
  const dispatch = useDispatch();
  const reportState = useSelector((state: Store) => state.report);
  const warehouseState = useSelector((state: Store) => state.warehouse);

  useThrottleEffect(() => {
    if (reloadKey > 0) {
      dispatch(reportActions.getProfiledProductsAsync(warehouseState.selected));
    }
  }, [warehouseState.selected, reloadKey]);
  return (
    <div className="flex flex-wrap">
      {Object.keys(reportState.profiledProducts.data).map((key) => {
        return (
          <div key={key} className="p-1  w-1/4">
            <div className="bg-white p-4 rounded-lg">
              <div className="sticky top-0">
                <h3 className="text-md ">
                  {key}{" "}
                  <Tooltip title="Daily Average">
                    <InfoCircleOutlined />
                  </Tooltip>
                </h3>
              </div>
              <div
                className="flex flex-col gap-y-2 mt-4"
                style={{
                  maxHeight: "50vh",
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                  paddingRight: "5px",
                  msScrollbarTrackColor: "transparent",
                }}
              >
                <List
                  loading={reportState.profiledProducts.loading}
                  bordered={false}
                  dataSource={reportState.profiledProducts.data[key]}
                  renderItem={(product: [string, number]) => {
                    const [skuCode, quantity] = product;
                    return (
                      <List.Item className="w-full flex items-center">
                        <div
                          key={skuCode}
                          className="flex justify-between w-full items-center"
                        >
                          <div className="font-semibold">{skuCode}</div>
                          <div>{quantity.toFixed(2)}</div>
                        </div>
                      </List.Item>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
function ProfiledProductsByChannel(props: any) {
  const { reloadKey } = props;
  const dispatch = useDispatch();
  const reportState = useSelector((state: Store) => state.report);
  const warehouseState = useSelector((state: Store) => state.warehouse);
  const [selectedChannel, setSelectedChannel] = useState("");
  const settingsState = useSelector((state: Store) => state.setting);
  useEffect(() => {
    if (reloadKey > 0) {
      dispatch(
        reportActions.getProfiledProductsByChannelAsync({
          warehouse: warehouseState.selected,
          channel: selectedChannel,
        })
      );
    }
  }, [warehouseState.selected, selectedChannel, reloadKey]);
  useEffect(() => {
    if (!selectedChannel) {
      setSelectedChannel(settingsState.channels[0]?._id);
    }
  }, [settingsState.channels, selectedChannel]);
  return (
    <div>
      <Select onChange={setSelectedChannel} value={selectedChannel}>
        {settingsState.channels.map((channel) => (
          <Select.Option key={channel._id} value={channel._id}>
            {channel.name}
          </Select.Option>
        ))}
      </Select>
      <div className="flex flex-wrap">
        {Object.keys(reportState.profiledProductsByChannel.data).map((key) => {
          return (
            <div key={key} className="p-1  w-1/4">
              <div className="bg-white p-4 rounded-lg">
                <div className="sticky top-0">
                  <h3 className="text-md ">
                    {key}{" "}
                    <Tooltip title="Daily Average">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </h3>
                </div>
                <div
                  className="flex flex-col gap-y-2 mt-4"
                  style={{
                    maxHeight: "50vh",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    paddingRight: "5px",
                    msScrollbarTrackColor: "transparent",
                  }}
                >
                  <List
                    loading={reportState.profiledProductsByChannel.loading}
                    bordered={false}
                    dataSource={reportState.profiledProductsByChannel.data[key]}
                    renderItem={(product: [string, number]) => {
                      const [skuCode, quantity] = product;
                      return (
                        <List.Item className="w-full flex items-center">
                          <div
                            key={skuCode}
                            className="flex justify-between w-full items-center"
                          >
                            <div className="font-semibold">{skuCode}</div>
                            <div>{quantity.toFixed(2)}</div>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
function ProfiledProductsByBrand(props: any) {
  const { reloadKey } = props;
  const dispatch = useDispatch();
  const reportState = useSelector((state: Store) => state.report);
  const warehouseState = useSelector((state: Store) => state.warehouse);
  const [selectedBrand, setSelectedBrand] = useState("");
  const settingsState = useSelector((state: Store) => state.setting);
  useEffect(() => {
    if (reloadKey > 0) {
      dispatch(
        reportActions.getProfiledProductsByBrandAsync({
          warehouse: warehouseState.selected,
          brand: selectedBrand,
        })
      );
    }
  }, [warehouseState.selected, selectedBrand, reloadKey]);
  useEffect(() => {
    if (!selectedBrand) {
      setSelectedBrand(settingsState.brands[0]?._id);
    }
  }, [settingsState.brands, selectedBrand]);
  return (
    <div>
      <Select onChange={setSelectedBrand} value={selectedBrand}>
        {settingsState.brands.map((brand) => (
          <Select.Option key={brand._id} value={brand._id}>
            {brand.name}
          </Select.Option>
        ))}
      </Select>
      <div className="flex flex-wrap">
        {Object.keys(reportState.profiledProductsByBrand.data).map((key) => {
          return (
            <div key={key} className="p-1  w-1/4">
              <div className="bg-white p-4 rounded-lg">
                <div className="sticky top-0">
                  <h3 className="text-md ">
                    {key}{" "}
                    <Tooltip title="Daily Average">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </h3>
                </div>
                <div
                  className="flex flex-col gap-y-2 mt-4"
                  style={{
                    maxHeight: "50vh",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    paddingRight: "5px",
                    msScrollbarTrackColor: "transparent",
                  }}
                >
                  <List
                    loading={reportState.profiledProductsByBrand.loading}
                    bordered={false}
                    dataSource={reportState.profiledProductsByBrand.data[key]}
                    renderItem={(product: [string, number]) => {
                      const [skuCode, quantity] = product;
                      return (
                        <List.Item className="w-full flex items-center">
                          <div
                            key={skuCode}
                            className="flex justify-between w-full items-center"
                          >
                            <div className="font-semibold">{skuCode}</div>
                            <div>{quantity.toFixed(2)}</div>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Dashboard(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settingState = useSelector((state: Store) => state.setting);
  const warehouseState = useSelector((state: Store) => state.warehouse);
  const purchaseWarehouseState = useSelector(
    (state: Store) => state.purchaseWarehouse
  );
  const clientState = useSelector((state: Store) => state.client);
  const skuState = useSelector((state: Store) => state.sku);
  const poState = useSelector((state: Store) => state.po);
  const [unassignedProducts, setUnassignedProducts] = useState<string[]>([]);
  const [apiKeyError, setApiKeyError] = useState("");
  const [apiKeyExpiry, setApiKeyExpiry] = useState<any>();
  const [nextBatchTime, setNextBatchTime] = useState<any>();
  const [pendingApprovalRWPOs, setPendingApprovalRWPOs] = useState<any[]>([]);
  const [reloadKey, setReloadKey] = useState(0);
  useEffect(() => {
    dispatch(settingActions.loadChannelsAsync({}));
    dispatch(settingActions.loadBrandsAsync({}));
  }, []);
  useEffect(() => {
    if (settingState.selectedApiKey) {
      const { errorObject, _id } = settingState.selectedApiKey;
      if (errorObject) {
        setApiKeyError(errorObject);
      } else {
        const { expiresAt } = settingState.selectedApiKey;
        if (dayjs(expiresAt).diff(dayjs(), "days", true) < 7) {
          setApiKeyExpiry(expiresAt);
        }
        handleGetNextBatchTime();
      }
    }
  }, [settingState.selectedApiKey]);
  useEffect(() => {
    rwpoService.getPendingApprovalPOs().then((response) => {
      setPendingApprovalRWPOs(response);
    });
  }, [clientState.current]);
  const handleGetNextBatchTime = () => {
    // API call to get next batch time
    batchService.getNextBatchRunTime().then((response) => {
      setNextBatchTime(response);
    });
  };
  const associatedStates = settingState.zones.reduce((acc, zone) => {
    return [...acc, ...zone.states];
  }, []);

  useEffect(() => {
    const products = poState.productsForClient.data.map((product) =>
      product._id.toLowerCase().trim()
    );

    const productsSet = new Set(products);

    const assignedProductsSet = new Set(
      poState.vendors.data
        .map((vendor) => vendor.products)
        .flat()
        .map((productCode) => productCode.toLowerCase().trim())
    );

    // console.log("products", assignedProductsSet);
    // console.log("productsSet", productsSet);

    const unassignedProducts = _.difference(
      Array.from(productsSet),
      Array.from(assignedProductsSet)
    );

    // console.log("unassignedProducts", unassignedProducts);

    setUnassignedProducts([...new Set(unassignedProducts)]);
  }, [poState.productsForClient.data, poState.vendors.data]);

  const handleExportUnassigned = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      unassignedProducts.map((product) => ({ Product: product }))
    );
    XLSX.utils.book_append_sheet(wb, ws, "Unassigned Products");
    XLSX.writeFile(wb, "unassigned-products.xlsx");
  };

  return (
    <div>
      <div className="flex flex-col gap-y-4">
        {!clientState.current && (
          <Alert type="error" showIcon message={`No Client Selected`} />
        )}
        {pendingApprovalRWPOs?.length > 0 && (
          <Alert
            type="warning"
            showIcon
            message={`There are ${pendingApprovalRWPOs.length} Purchase Orders Pending Approval.`}
            action={
              <Button
                size="small"
                type="link"
                onClick={() => navigate("/raw-material-purchase-orders")}
              >
                View Now
              </Button>
            }
          />
        )}
        {unassignedProducts.length > 0 && (
          <Alert
            type="error"
            showIcon
            message={`There are ${unassignedProducts.length} unassigned products.`}
            action={
              <Space>
                <Button
                  size="small"
                  type="link"
                  onClick={() => navigate("/settings/vendor")}
                >
                  Assign Now
                </Button>
                <Button type="link" onClick={() => handleExportUnassigned()}>
                  Export
                </Button>
              </Space>
            }
          />
        )}
        {skuState.unmapped.length > 0 && (
          <Alert
            type="error"
            showIcon
            message={`There are ${skuState.unmapped.length} unmapped SKUs, this may result in incorrect reports.`}
            action={
              <Button
                size="small"
                type="link"
                onClick={() => navigate("/settings/mapping")}
              >
                Upload Now
              </Button>
            }
          />
        )}
        {/* // Show an error message if any states of india are not part of a zone in the system */}
        {statesOfIndia.filter((state) => !associatedStates.includes(state.name))
          .length > 0 && (
          <Alert
            message={
              "States are not associated with any Sale Zone: " +
              statesOfIndia
                .filter((state) => !associatedStates.includes(state.name))
                .map((state) => state.name)
                .join(", ")
            }
            type="warning"
            showIcon
            action={
              <Button
                size="small"
                type="link"
                onClick={() => navigate("/settings/zone")}
              >
                Setup Now
              </Button>
            }
          />
        )}
        {/* // Show an error message if no active key is found */}

        {apiKeyError === API_KEY_ERRORS.NO_ACTIVE_KEY && (
          <Alert
            message="No Active Key Found"
            type="error"
            showIcon
            action={
              <Button
                size="small"
                type="link"
                onClick={() => navigate("/settings/api-key")}
              >
                Setup Now
              </Button>
            }
          />
        )}
        {apiKeyError === API_KEY_ERRORS.ACTIVE_KEY_IS_INVALID && (
          <Alert
            message="Active Key is Invalid"
            type="error"
            showIcon
            action={
              <Button
                size="small"
                type="link"
                onClick={() => navigate("/settings/api-key")}
              >
                Correct Now
              </Button>
            }
          />
        )}
        {apiKeyExpiry && (
          <Alert
            message={`Active Key is Expiring in ${
              dayjs(apiKeyExpiry).diff(dayjs(), "days", true) > 1
                ? dayjs(apiKeyExpiry).diff(dayjs(), "days", true).toFixed(1) +
                  " days"
                : dayjs(apiKeyExpiry).diff(dayjs(), "hours", true).toFixed(1) +
                  " hours"
            }`}
            type="warning"
            showIcon
            action={
              <Button
                size="small"
                type="link"
                onClick={() => navigate("/settings/api-key")}
              >
                Renew Now
              </Button>
            }
          />
        )}
        {nextBatchTime && (
          <Alert
            message={`Next Batch Run Time: ${dayjs(nextBatchTime).format(
              "DD/MM/YYYY hh:mm A"
            )}`}
            type="info"
            showIcon
          />
        )}
      </div>
      <div className="bg-white p-2 rounded-lg mt-3">
        <OMSWarehouseSelect />
      </div>
      <div>
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={
            <Button
              type="link"
              size="small"
              onClick={() => setReloadKey((prev) => prev + 1)}
            >
              Load
            </Button>
          }
          items={[
            {
              key: "1",
              label: "Global",
              children: <GlobalDashboard reloadKey={reloadKey} />,
            },
            {
              key: "2",
              label: "By Channel",
              children: <ProfiledProductsByChannel reloadKey={reloadKey} />,
            },
            {
              key: "3",
              label: "By Brand",
              children: <ProfiledProductsByBrand reloadKey={reloadKey} />,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Dashboard;
