import {
  Button,
  Collapse,
  Form,
  InputNumber,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import service from "../../services/po";
import skuService from "../../services/sku";
import { useEffect, useRef, useState } from "react";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import { BASE_PATH } from ".";
import * as poActions from "../../store/poSlice";
import { SyncOutlined, PlusOutlined } from "@ant-design/icons";

export default function EntityForm(props: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const vendor = Form.useWatch("vendor", form);
  const params = useParams();
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const poState = useSelector((state: Store) => state.po);
  const addRef = useRef<(defaultValue?: any, insertIndex?: number) => void>();
  const removeRef = useRef<(index: number | number[]) => void>();
  const [selectedVendor, setSelectedVendor] = useState<any>();
  const products = Form.useWatch("products", form);
  const skus = Form.useWatch("skus", form);
  const [pendingQuantities, setPendingQuantities] = useState<any[]>([]);
  const [skuCodeMapForPending, setSkuCodeMapForPending] = useState<any>({});
  const [skuCodeMapForStock, setSkuCodeMapForStock] = useState<any>({});

  useEffect(() => {
    if (params.id) {
      setId(params.id);
      service.get(params.id).then((res) => {
        const products = res.skus.map((s) => s.productCode);
        form.setFieldsValue({ ...res, vendor: res.vendor._id, products });
        setSelectedVendor(res.vendor);

        setData(res);
      });
    }
  }, [params.id]);

  const getSkuStocks = async (skuCodes: string[]) => {
    const stocks = await skuService.getStockBySkuCodes(skuCodes);
    return stocks;
  };

  const handleFinish = async (values: any) => {
    // for (let i = 0; i < values.skus.length; i++) {
    //   const sku = values.skus[i];
    //   const quantities = sku.quantities.filter((q) => q.ordered > 0);
    //   values.skus[i].quantities = quantities;
    // }

    try {
      if (id) {
        await service.update({ _id: id, ...values });
      } else {
        await service.register(values);
      }
      navigate(BASE_PATH);
    } catch (error) {
      message.error(error.message);
      throw error;
    }
  };

  const refreshVendors = () => {
    dispatch(poActions.fetchVendors());
    dispatch(poActions.fetchProductsForClient());
  };

  const getPendingQuantities = async (
    products: { _id: string; skus: any[] }[]
  ) => {
    const pendingQuantities = await service.getPendingQuantityForSKUs(
      products.flatMap((p) => p.skus.map((s) => s.skuCode))
    );
    console.log("pendingQuantities", pendingQuantities);
    const skuCodeMapForPending = pendingQuantities.reduce((acc, pq) => {
      acc[pq.skuCode] = (acc[pq.skuCode] || 0) + pq.pending;
      return acc;
    }, {});
    setSkuCodeMapForPending(skuCodeMapForPending);
    console.log("skuCodeMapForPending", skuCodeMapForPending);
    setPendingQuantities(pendingQuantities);
  };

  useEffect(() => {
    console.log("products", products);

    if (!products) {
      return;
    }

    const productsFromMaster = poState.productsForClient.data.filter((p) =>
      products.includes(p._id)
    );
    getPendingQuantities(productsFromMaster);
    console.log("productsFromMaster", productsFromMaster);

    const currentSkus = form.getFieldValue("skus") || [];

    const skusToBeAdded = productsFromMaster
      .filter((p) => !currentSkus.map((s) => s.productCode).includes(p._id))
      .map((p) => ({
        productCode: p._id,
        quantities: p.skus

          .map((sku) => ({
            skuCode: sku.skuCode,
            ordered: 0,
            size: sku.parts[sku.parts.length - 1],
          }))
          .sort((a, b) => +a.size - +b.size),
      }));

    const skusIndexesToBeRemoved = currentSkus
      .map((s, index) => (products.includes(s.productCode) ? null : index))
      .filter((i) => i !== null);

    if (addRef.current) {
      skusToBeAdded.forEach((sku) => addRef.current(sku));
    }

    if (removeRef.current) {
      skusIndexesToBeRemoved.forEach((sku) => removeRef.current(sku));
    }

    const fetchSkuStocks = async (skuCodes: string[]) => {
      const stocks = await getSkuStocks(skuCodes);
      const map = stocks.reduce((acc, cur) => {
        acc[cur.skuCode] = cur.inStock;
        return acc;
      }, {});
      setSkuCodeMapForStock(map);
    };

    const allSkuCodes = productsFromMaster.flatMap((p) =>
      p.skus.map((s) => s.skuCode)
    );
    fetchSkuStocks(allSkuCodes);
  }, [products]);

  useEffect(() => {
    setSelectedVendor(poState.vendors.data.find((v) => v._id === vendor));
  }, [vendor, poState.vendors.data]);

  return (
    <div>
      <Title level={3}>{id ? "Edit" : "Add"} Purchase Order</Title>
      <Form form={form} onFinish={handleFinish} className="mt-8">
        {data?.id && (
          <Form.Item name="id" label="ID">
            {data?.id}
          </Form.Item>
        )}
        <div className="flex  gap-x-2">
          <Form.Item name="vendor" label="Vendor">
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "300px" }}
              loading={poState.vendors.loading}
              placeholder="Select a vendor"
              allowClear
              disabled={!!id}
            >
              {poState.vendors.data.map((vendor) => (
                <Select.Option key={vendor._id} value={vendor._id}>
                  {vendor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            icon={<SyncOutlined />}
            type="link"
            onClick={refreshVendors}
          />
        </div>
        <Form.Item label="Products" name="products">
          <Select
            mode="multiple"
            showSearch
            style={{ width: "300px" }}
            placeholder="Select products"
          >
            {poState.productsForClient.data
              .filter((product) =>
                selectedVendor?.products.some((vp) =>
                  product._id.match(new RegExp(`^${vp}$`, "i"))
                )
              )
              .map((product) => (
                <Select.Option key={product._id} value={product._id}>
                  {product.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.List name="skus">
          {(fields, { add, remove }) => {
            addRef.current = add;
            removeRef.current = remove;
            return (
              <div>
                <Collapse size="small" defaultActiveKey={"0"}>
                  {fields.map((field, index) => (
                    <Collapse.Panel
                      forceRender
                      header={
                        <div className="flex gap-x-2 justify-between">
                          {form.getFieldValue(["skus", index, "productCode"])}
                          <div className="flex gap-x-2">
                            {form
                              .getFieldValue(["skus", index, "quantities"])
                              .map((q) => {
                                const tokens = q.skuCode.split("_");
                                return (
                                  <Tag className="flex gap-x-2">
                                    <span className="font-semibold">
                                      {tokens[tokens.length - 1]}:
                                    </span>
                                    <span>{q.ordered}</span>
                                  </Tag>
                                );
                              })}
                            <Tag className="flex gap-x-2">
                              <span className="font-semibold">Total:</span>
                              <span>
                                {form
                                  .getFieldValue(["skus", index, "quantities"])
                                  .reduce((acc, q) => acc + q.ordered, 0)}
                              </span>
                            </Tag>
                          </div>
                        </div>
                      }
                      key={index}
                    >
                      <Table
                        size="small"
                        bordered
                        pagination={false}
                        dataSource={
                          form.getFieldValue([
                            "skus",
                            index,
                            "quantities",
                          ]) as any[]
                        }
                        columns={[
                          {
                            title: "SKU Code",
                            dataIndex: "skuCode",
                            key: "skuCode",
                          },
                          {
                            title: "OMS Stock",
                            key: "stock",
                            render: (text, record) =>
                              skuCodeMapForStock[record.skuCode] || 0,
                          },
                          {
                            title: "Pending",
                            key: "pending",
                            render: (text, record, index) => {
                              const pending =
                                skuCodeMapForPending[record.skuCode];
                              return pending ? pending : 0;
                            },
                          },
                          {
                            title: "Ordered",
                            dataIndex: "ordered",
                            key: "ordered",
                            render: (text, record, index) => {
                              return (
                                <Form.Item
                                  noStyle
                                  {...field}
                                  name={[
                                    field.name,
                                    "quantities",
                                    index,
                                    "ordered",
                                  ]}
                                >
                                  <InputNumber placeholder="Received" />
                                </Form.Item>
                              );
                            },
                          },
                        ]}
                      />
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </div>
            );
          }}
        </Form.List>
        <Form.Item className="mt-4">
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
