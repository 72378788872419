import { useCallback, useEffect, useRef, useState } from "react";
import { BASE_PATH, TITLE } from ".";
import {
  Alert,
  message,
  Button,
  Collapse,
  Table,
  Form,
  Select,
  Tabs,
  Modal,
  Input,
  InputNumber,
  DatePicker,
  Tooltip,
  Tag,
  Space,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { SyncOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import * as poActions from "../../store/poSlice";
import { Store } from "../../store";
import poService from "../../services/po";
import grnService from "../../services/grn";
import settingsService from "../../services/settings";
import { Info, SKUTable } from "../PurchaseOrder/View";
import dayjs from "dayjs";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

export default function EntityForm() {
  const dispatch = useDispatch();
  const poState = useSelector((state: Store) => state.po);
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const selectedVendor = Form.useWatch("vendor", form);
  const clientState = useSelector((state: Store) => state.client);
  const purchaseOrdersForm = Form.useWatch("purchaseOrders", form);
  const [purchaseOrders, setPurchaseOrders] = useState<any[]>([]);
  const [selectedPOs, setSelectedPOs] = useState<any[]>([]);
  const [addPOVisible, setAddPOVisible] = useState(false);
  const [mappings, setMappings] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [defaultParams, setDefaultParams] = useState<any>({});
  const purchaseWarehouseState = useSelector(
    (state: Store) => state.purchaseWarehouse
  );
  const addRef = useRef<(defaultValue?: any, insertIndex?: number) => void>();
  const removeRef = useRef<(index: number | number[]) => void>();
  useEffect(() => {
    handleLoadDependencies();
  }, []);
  useEffect(() => {
    console.log(location);
    const search = new URLSearchParams(location.search);
    if (search.has("po") && search.has("vendor")) {
      const poId = search.get("po");
      const vendorId = search.get("vendor");
      setDefaultParams({ poId, vendorId });
    }
  }, [location]);

  useEffect(() => {
    if (
      poState.vendors.data &&
      Object.keys(defaultParams).length > 0 &&
      !selectedVendor
    ) {
      if (defaultParams.poId && defaultParams.vendorId) {
        const selectedVendor = poState.vendors.data.find(
          (vendor) => vendor._id === defaultParams.vendorId
        );
        if (selectedVendor) {
          form.setFieldsValue({
            vendor: selectedVendor._id,
          });
        }
      }
    }
  }, [defaultParams, poState.vendors.data, selectedVendor]);

  useEffect(() => {
    if (!selectedVendor) {
      return;
    }
    getChallanID(selectedVendor);
  }, [selectedVendor]);

  const getChallanID = async (vendorId: string) => {
    try {
      const challanID = await grnService.generateVendorChallanID(vendorId);
      form.setFieldsValue({ vendorChallanID: challanID });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadDependencies = async () => {
    try {
      dispatch(poActions.fetchProducts());
      dispatch(poActions.fetchVendors());
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (selectedVendor) {
      loadPOsForVendor(selectedVendor);
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (selectedVendor) {
      if (defaultParams.poId) {
        const selectedPO = purchaseOrders.find(
          (po) => po._id === defaultParams.poId
        );
        console.log(selectedPO);
        if (selectedPO) {
          setSelectedPOs([selectedPO]);
          form.setFieldsValue({
            purchaseOrders: [selectedPO],
          });
        }
      }
    }
  }, [selectedVendor, defaultParams, purchaseOrders]);

  useEffect(() => {
    if (!purchaseOrdersForm || purchaseOrdersForm.length === 0) {
      return;
    }
    const skuCodeSet = new Set<string>();
    for (let purchaseOrder of purchaseOrdersForm) {
      for (let sku of purchaseOrder.skus) {
        skuCodeSet.add(
          sku.quantities.reduce(
            (acc, skuQuantity) => acc.concat(skuQuantity.skuCode),
            []
          )
        );
      }
    }
    const skuCodes = Array.from(skuCodeSet).flat();
    settingsService.getMappingForSkuCodes(skuCodes).then((mappings) => {
      setMappings(mappings);
    });
  }, [purchaseOrdersForm]);

  const loadPOsForVendor = async (vendorId: string) => {
    try {
      const pos = await poService.getForVendorId(vendorId);
      setPurchaseOrders(pos);
    } catch (error) {
      throw error;
    }
  };

  const handleFinish = async (values: any) => {
    try {
      const data = {
        ...values,
        vendorChallanDate: values.vendorChallanDate.toISOString(),
      };

      const goodsReceiveNotes = [];

      for (let purchaseOrder of data.purchaseOrders) {
        let totalRejectedQuantity = 0,
          totalReceivedQuantity = 0;
        const payload = {
          vendorChallanDate: data.vendorChallanDate,
          vendorChallanID: data.vendorChallanID,
          vendor: data.vendor,
          purchaseOrder: purchaseOrder._id,
          totalRejectedQuantity: 0,
          totalReceivedQuantity: 0,
          skus: [],
        };
        for (let productCode in purchaseOrder.payload) {
          const sku = {
            productCode,
            quantities: [],
            purchasePrice: purchaseOrder.payload[productCode].purchasePrice,
          };
          for (let size in purchaseOrder.payload[productCode]) {
            if (isNaN(+size)) {
              continue;
            }
            const received =
              purchaseOrder.payload[productCode][size].received || 0;
            const rejected =
              purchaseOrder.payload[productCode][size].rejected || 0;

            if (received === 0 && rejected === 0) {
              continue;
            }

            totalReceivedQuantity += received;
            totalRejectedQuantity += rejected;

            sku.quantities.push({
              skuCode: `${productCode}_${size}`,
              size: size,
              received,
              rejected,
            });
          }
          if (sku.quantities.length > 0) {
            payload.skus.push(sku);
          }
        }
        if (totalReceivedQuantity === 0 && totalRejectedQuantity === 0) {
          continue;
        }
        payload.totalRejectedQuantity = totalRejectedQuantity;
        payload.totalReceivedQuantity = totalReceivedQuantity;
        goodsReceiveNotes.push(payload);
      }
      console.log(goodsReceiveNotes);
      await grnService.createMultiple(goodsReceiveNotes);
      message.success("Goods Receive Note created successfully");
      navigate(BASE_PATH);
    } catch (error) {
      console.log(error);

      message.error("Failed to create Goods Receive Note");
    }
  };

  const handleAddDefaultPurchasePrice = useCallback(
    (purchaseOrderIndex: number, expandedKeys: string[]) => {
      if (expandedKeys.length === 0) {
        return;
      }
      const purchaseOrders = purchaseOrdersForm[purchaseOrderIndex];
      const skus = purchaseOrders.skus.filter((sku, index) =>
        expandedKeys.includes(index.toString())
      );
      for (let sku of skus) {
        let purchasePrice = 0;
        for (let skuQuantity of sku.quantities) {
          const mapping = mappings.find(
            (mapping) => mapping.omsSkuCode === skuQuantity.skuCode
          );
          if (mapping) {
            purchasePrice = mapping.purchasePrice;
          }
        }
        if (purchasePrice) {
          if (
            !purchaseOrdersForm[purchaseOrderIndex].payload?.[sku.productCode]
          ) {
            const purchaseOrdersDeepCopy = _.cloneDeep(purchaseOrdersForm);
            if (!purchaseOrdersDeepCopy[purchaseOrderIndex].payload) {
              purchaseOrdersDeepCopy[purchaseOrderIndex].payload = {};
            }
            if (
              !purchaseOrdersDeepCopy[purchaseOrderIndex].payload[
                sku.productCode
              ]
            ) {
              purchaseOrdersDeepCopy[purchaseOrderIndex].payload[
                sku.productCode
              ] = {};
            }
            purchaseOrdersDeepCopy[purchaseOrderIndex].payload[
              sku.productCode
            ].purchasePrice = purchasePrice;
            purchaseOrdersDeepCopy[purchaseOrderIndex].payload[
              sku.productCode
            ].defaultPurchasePrice = purchasePrice;
            form.setFieldsValue({
              purchaseOrders: purchaseOrdersDeepCopy,
            });
          }
        }
      }
    },
    [mappings, purchaseOrdersForm]
  );

  useEffect(() => {
    console.log(errors);
  }, [errors]);
  useEffect(() => {
    console.log("selectedPOs", selectedPOs);
  }, [selectedPOs]);

  const refreshVendors = () => {
    dispatch(poActions.fetchVendors());
    dispatch(poActions.fetchProductsForClient());
  };

  // Add this new effect to handle vendor changes
  useEffect(() => {
    if (selectedVendor) {
      // Clear selected POs when vendor changes
      setSelectedPOs([]);
      form.setFieldValue("purchaseOrders", []);
    }
  }, [selectedVendor]); // Only depend on vendor changes

  // Modify existing effect to only handle defaultParams
  useEffect(() => {
    if (selectedVendor && defaultParams.poId) {
      const selectedPO = purchaseOrders.find(
        (po) => po._id === defaultParams.poId
      );
      if (selectedPO) {
        setSelectedPOs([selectedPO]);
        form.setFieldsValue({
          purchaseOrders: [selectedPO],
        });
      }
    }
  }, [defaultParams, purchaseOrders]);

  return (
    <div>
      <Modal
        open={addPOVisible}
        onCancel={() => setAddPOVisible(false)}
        title="Add Purchase Order"
        onOk={() => setAddPOVisible(false)}
      >
        <Collapse size="small">
          {purchaseOrders.map((purchaseOrder, index) => (
            <Collapse.Panel
              header={purchaseOrder.id}
              key={index}
              extra={
                <Button
                  size="small"
                  type={
                    selectedPOs.some((po) => purchaseOrder._id === po._id)
                      ? "default"
                      : "primary"
                  }
                  onClick={
                    selectedPOs.some((po) => purchaseOrder._id === po._id)
                      ? (e) => {
                          e.stopPropagation();
                          setSelectedPOs(
                            selectedPOs.filter(
                              (selectedPO) =>
                                selectedPO._id !== purchaseOrder._id
                            )
                          );
                          const index = purchaseOrdersForm.findIndex(
                            (po) => po._id === purchaseOrder._id
                          );

                          removeRef.current?.(index);
                        }
                      : (e) => {
                          e.stopPropagation();
                          setSelectedPOs([...selectedPOs, purchaseOrder]);
                          addRef.current?.(purchaseOrder);
                        }
                  }
                >
                  {selectedPOs.some((po) => purchaseOrder._id === po._id)
                    ? "Unselect"
                    : "Select"}
                </Button>
              }
            >
              <Info data={purchaseOrder} />
              <SKUTable data={purchaseOrder} />
            </Collapse.Panel>
          ))}
        </Collapse>
      </Modal>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        New {TITLE[0]}
      </div>
      <Form
        form={form}
        className="mt-4"
        onFinish={handleFinish}
        initialValues={{}}
        onValuesChange={async (changedValues, allValues) => {
          try {
            await form.validateFields();
          } catch (error) {
            setErrors(error);
          }
        }}
        scrollToFirstError
      >
        <div className="bg-white rounded-lg p-2">
          <div className="flex  gap-x-2">
            <Form.Item
              name="vendor"
              label="Vendor"
              rules={[{ required: true, message: "Vendor is required" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "300px" }}
                loading={poState.vendors.loading}
                placeholder="Select a vendor"
                allowClear
              >
                {poState.vendors.data.map((vendor) => (
                  <Select.Option key={vendor._id} value={vendor._id}>
                    {vendor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              icon={<SyncOutlined />}
              type="link"
              onClick={refreshVendors}
            />
          </div>

          <Form.Item
            name="vendorChallanID"
            label="Vendor Challan ID"
            rules={[
              { required: true, message: "Vendor Challan ID is required" },
            ]}
            tooltip={{
              title: "This is the session ID",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input
              disabled
              placeholder="Vendor Challan ID"
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item
            name="vendorChallanDate"
            label="Vendor Challan Date"
            rules={[
              { required: true, message: "Vendor Challan Date is required" },
            ]}
          >
            <DatePicker allowClear={false} />
          </Form.Item>
          <Form.Item label="Total Received">
            {purchaseOrdersForm
              ?.map((po) => po.payload)
              .flat()
              .map((p) => Object.values(p || {}))
              .flat()
              .map((p) => Object.values(p || {}))
              .flat()
              .filter((p) => !!p)
              .filter((p) => p.received)
              .reduce((acc, val) => acc + val.received, 0)}
          </Form.Item>
        </div>
        {selectedVendor && (
          <div className="mt-4 flex items-center gap-x-2">
            <Alert
              type="info"
              message={`${purchaseOrders.length} purchase order available`}
              showIcon
            />
            <Button onClick={() => loadPOsForVendor(selectedVendor)}>
              Refresh POs
            </Button>
          </div>
        )}
        <Form.List name="purchaseOrders">
          {(fields, { add, remove }) => {
            addRef.current = add;
            removeRef.current = remove;
            return (
              <div
                className="mt-4"
                style={{ display: "flex", rowGap: 16, flexDirection: "column" }}
              >
                <Button
                  type="dashed"
                  onClick={() => setAddPOVisible(true)}
                  disabled={!selectedVendor || purchaseOrders.length === 0}
                  block
                >
                  + Add Purchase Order
                </Button>
                <Tabs
                  defaultActiveKey="0"
                  items={
                    fields.map((field, poIndex) => {
                      return {
                        key: field.key,
                        label: purchaseOrdersForm[poIndex].id,
                        children: (
                          <Collapse
                            size="small"
                            onChange={(expandedKeys: string[]) =>
                              handleAddDefaultPurchasePrice(
                                poIndex,
                                expandedKeys
                              )
                            }
                          >
                            {purchaseOrdersForm[poIndex].skus.map(
                              (sku, index) => {
                                const defaultPurchasePrice =
                                  purchaseOrdersForm[poIndex].payload?.[
                                    sku.productCode
                                  ]?.defaultPurchasePrice;
                                const validatePurchasePrice = async (
                                  _,
                                  value
                                ) => {
                                  const received = form.getFieldValue([
                                    "purchaseOrders",
                                    poIndex,
                                    "payload",
                                    sku.productCode,
                                    "received",
                                  ]);
                                  const rejected = form.getFieldValue([
                                    "purchaseOrders",
                                    poIndex,
                                    "payload",
                                    sku.productCode,
                                    "rejected",
                                  ]);
                                  if (
                                    (received || rejected) &&
                                    (!value || value <= 0)
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "Purchase Price is required and should be non-zero"
                                      )
                                    );
                                  }
                                  return Promise.resolve();
                                };
                                const parentErrors = errors.errorFields?.filter(
                                  (ef) => ef.name[1] === poIndex
                                );

                                const error = parentErrors?.filter(
                                  (pe) => pe.name[3] === sku.productCode
                                );

                                if (error?.length > 0) {
                                  console.log(sku.productCode, error);
                                }

                                const sizeColumns = Array.from(
                                  new Set(sku.quantities.map((q) => q.size))
                                );

                                return (
                                  <Collapse.Panel
                                    header={
                                      <div className="flex gap-x-2">
                                        <div
                                          className={
                                            error?.length > 0
                                              ? "text-red-500"
                                              : ""
                                          }
                                        >
                                          {sku.productCode}
                                        </div>
                                        <div className="flex gap-x-2">
                                          {sizeColumns.map((size: string) => {
                                            const quantity =
                                              sku.quantities.find(
                                                (q) => q.size === size
                                              );

                                            return (
                                              <Tooltip
                                                title={`${quantity?.received} received & ${quantity?.rejected} rejected`}
                                              >
                                                <Tag>
                                                  <span className="font-medium pr-2">
                                                    {size}:{"  "}
                                                  </span>
                                                  <Space>
                                                    <span className="text-green-600">
                                                      {form.getFieldValue([
                                                        "purchaseOrders",
                                                        field.name,
                                                        "payload",
                                                        sku.productCode,
                                                        size,
                                                        "received",
                                                      ]) || 0}
                                                    </span>
                                                    <span className="text-gray-300">
                                                      /
                                                    </span>
                                                    <span className="text-red-700">
                                                      {form.getFieldValue([
                                                        "purchaseOrders",
                                                        field.name,
                                                        "payload",
                                                        sku.productCode,
                                                        size,
                                                        "rejected",
                                                      ]) || 0}
                                                    </span>
                                                  </Space>
                                                </Tag>
                                              </Tooltip>
                                            );
                                          })}
                                          <span>
                                            <span className="font-medium pr-2">
                                              Total:
                                            </span>
                                            <span>
                                              {sku.quantities.reduce(
                                                (acc, q) =>
                                                  acc +
                                                  (form.getFieldValue([
                                                    "purchaseOrders",
                                                    field.name,
                                                    "payload",
                                                    sku.productCode,
                                                    q.size,
                                                    "received",
                                                  ]) || 0),
                                                0
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    }
                                    key={index}
                                  >
                                    <div className="flex gap-x-2 items-start">
                                      <Form.Item
                                        shouldUpdate={(
                                          prevValues,
                                          currentValues
                                        ) =>
                                          prevValues.purchaseOrders?.[poIndex]
                                            ?.payload?.[sku.productCode]
                                            ?.received !==
                                            currentValues.purchaseOrders?.[
                                              poIndex
                                            ]?.payload?.[sku.productCode]
                                              ?.received ||
                                          prevValues.purchaseOrders?.[poIndex]
                                            ?.payload?.[sku.productCode]
                                            ?.rejected !==
                                            currentValues.purchaseOrders?.[
                                              poIndex
                                            ]?.payload?.[sku.productCode]
                                              ?.rejected
                                        }
                                        noStyle
                                      >
                                        {({ getFieldValue }) => {
                                          const productCodePayload =
                                            getFieldValue([
                                              "purchaseOrders",
                                              poIndex,
                                              "payload",
                                              sku.productCode,
                                            ]);

                                          let received = 0,
                                            rejected = 0;

                                          if (productCodePayload) {
                                            for (let size in productCodePayload) {
                                              if (isNaN(+size)) {
                                                continue;
                                              }
                                              received +=
                                                productCodePayload[size]
                                                  .received;
                                              rejected +=
                                                productCodePayload[size]
                                                  .rejected;
                                            }
                                          }
                                          return (
                                            <Form.Item
                                              name={[
                                                field.name,
                                                "payload",
                                                sku.productCode,
                                                "purchasePrice",
                                              ]}
                                              label="Purchase Price"
                                              rules={[
                                                { required: true },
                                                {
                                                  validator: (_, value) => {
                                                    if (
                                                      (received || rejected) &&
                                                      (!value || value <= 0)
                                                    ) {
                                                      return Promise.reject(
                                                        new Error(
                                                          "Purchase Price is required and should be non-zero"
                                                        )
                                                      );
                                                    }
                                                    return Promise.resolve();
                                                  },
                                                },
                                              ]}
                                            >
                                              <InputNumber
                                                placeholder="Purchase Price"
                                                prefix="₹"
                                                style={{ width: "300px" }}
                                              />
                                            </Form.Item>
                                          );
                                        }}
                                      </Form.Item>
                                      {defaultPurchasePrice ? (
                                        <Alert
                                          style={{ padding: "4px 12px" }}
                                          type="info"
                                          showIcon
                                          message={`Default purchase price: ₹${defaultPurchasePrice}`}
                                        />
                                      ) : (
                                        <Alert
                                          style={{ padding: "4px 12px" }}
                                          type="warning"
                                          showIcon
                                          message={`Default purchase price: None`}
                                        />
                                      )}
                                    </div>
                                    <Table
                                      pagination={false}
                                      size="small"
                                      dataSource={sku.quantities as any[]}
                                      columns={[
                                        {
                                          title: "SKU",
                                          dataIndex: "skuCode",
                                          key: "skuCode",
                                        },
                                        {
                                          title: "Ordered",
                                          dataIndex: "ordered",
                                          key: "ordered",
                                        },
                                        {
                                          title: "Pending",
                                          dataIndex: "pending",
                                          key: "pending",
                                        },
                                        {
                                          title: "Received",
                                          key: "received",
                                          render: (text, record, index) => {
                                            return (
                                              <Form.Item
                                                noStyle
                                                rules={[
                                                  {
                                                    validator: (_, value) => {
                                                      const received =
                                                        form.getFieldValue([
                                                          "purchaseOrders",
                                                          field.name,
                                                          "payload",
                                                          sku.productCode,
                                                          record.size,
                                                          "rejected",
                                                        ]);

                                                      const pending =
                                                        sku.quantities.find(
                                                          (q) =>
                                                            q.size ===
                                                            record.size
                                                        )?.pending || 0;

                                                      if (
                                                        (value || 0) +
                                                          (received || 0) >
                                                        pending
                                                      ) {
                                                        return Promise.reject(
                                                          new Error(
                                                            `Total received and rejected quantity cannot exceed pending quantity of ${pending}`
                                                          )
                                                        );
                                                      }
                                                      return Promise.resolve();
                                                    },
                                                  },
                                                ]}
                                                {...field}
                                                name={[
                                                  field.name,
                                                  "payload",
                                                  sku.productCode,
                                                  record.size,
                                                  "received",
                                                ]}
                                              >
                                                <InputNumber placeholder="Received" />
                                              </Form.Item>
                                            );
                                          },
                                        },
                                        {
                                          title: "Rejected",
                                          key: "rejected",
                                          render: (text, record, index) => {
                                            return (
                                              <Form.Item
                                                noStyle
                                                rules={[
                                                  {
                                                    validator: (_, value) => {
                                                      const received =
                                                        form.getFieldValue([
                                                          "purchaseOrders",
                                                          field.name,
                                                          "payload",
                                                          sku.productCode,
                                                          record.size,
                                                          "received",
                                                        ]);

                                                      const pending =
                                                        sku.quantities.find(
                                                          (q) =>
                                                            q.size ===
                                                            record.size
                                                        )?.pending || 0;

                                                      if (
                                                        (value || 0) +
                                                          (received || 0) >
                                                        pending
                                                      ) {
                                                        return Promise.reject(
                                                          new Error(
                                                            `Total received and rejected quantity cannot exceed pending quantity of ${pending}`
                                                          )
                                                        );
                                                      }
                                                      return Promise.resolve();
                                                    },
                                                  },
                                                ]}
                                                name={[
                                                  field.name,
                                                  "payload",
                                                  sku.productCode,
                                                  record.size,
                                                  "rejected",
                                                ]}
                                              >
                                                <InputNumber placeholder="Rejected" />
                                              </Form.Item>
                                            );
                                          },
                                        },
                                      ]}
                                    />
                                  </Collapse.Panel>
                                );
                              }
                            )}
                          </Collapse>
                        ),
                      };
                    }) as any
                  }
                />
              </div>
            );
          }}
        </Form.List>
        <Form.Item className="mt-4">
          <Button
            type="primary"
            disabled={!clientState.current}
            onClick={() => form.submit()}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
