import React, { useEffect, useState } from "react";
import { Button, Modal, Alert } from "antd";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import service from "../services/po";
import { FilePdfOutlined } from "@ant-design/icons";
import getDocDefinition from "../utils/getPODocDefinition";
import settingsService from "../services/settings";
import { useSelector } from "react-redux";
import { Store } from "../store";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function PurchaseOrderPDFModal(props: any) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [data, setData] = useState<any>();
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const clientState = useSelector((state: Store) => state.client);

  const showModal = async () => {
    if (!data) return;

    const { skus } = data;

    const skuCodes = skus
      .map((sku: any) => sku.quantities)
      .flat()
      .map((quantity: any) => quantity.skuCode);

    const mappings = await settingsService.getMappingForSkuCodes(skuCodes);

    console.log(skuCodes);

    // Check for missing mappings
    const missingMappings = skuCodes.reduce((acc: any, skuCode: string) => {
      if (!mappings.some((m: any) => m.omsSkuCode?.includes(skuCode))) {
        // Extract productCode and size from skuCode (assuming format: productCode_size)
        const [productCode, size] = skuCode.split("_");
        if (!acc[productCode]) {
          acc[productCode] = [];
        }
        acc[productCode].push(size);
      }
      return acc;
    }, {});

    if (Object.keys(missingMappings).length > 0) {
      const warningText = Object.entries(missingMappings)
        .map(
          ([productCode, sizes]) =>
            `${productCode} (sizes: ${(sizes as string[]).join(", ")})`
        )
        .join("; ");
      setWarningMessage(`Mappings not found for: ${warningText}`);
    } else {
      setWarningMessage(null);
    }

    for (let i = 0; i < skus.length; i++) {
      const sku = skus[i];
      sku.mapping = mappings.find((m) =>
        m.omsSkuCode?.includes(sku.productCode)
      );
    }

    console.log(data);

    // Define the PDF document
    const docDefinition: any = getDocDefinition(data);

    // Generate the PDF
    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
      setIsModalVisible(true);
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setPdfUrl(null);
  };

  useEffect(() => {
    if (props.id) {
      service.get(props.id).then((res) => {
        const clientData = clientState.clients.find(
          (c) => c._id === res.client
        );
        setData({ ...res, warehouse: clientData?.alias });
        showModal();
      });
    }
  }, [props.id]);

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<FilePdfOutlined />}>
        Show PDF
      </Button>
      <Modal
        title="PDF Viewer"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        style={{ top: 20, maxWidth: "80%" }}
      >
        {warningMessage && (
          <Alert
            message={warningMessage}
            type="warning"
            showIcon
            className="mb-4"
          />
        )}
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            style={{ width: "100%", height: "1020px", border: "none" }}
          />
        )}
      </Modal>
    </>
  );
}
