import {
  Row,
  Col,
  Typography,
  Button,
  Space,
  Table,
  Select,
  Tag,
  Modal,
  Input,
} from "antd";
import { BASE_PATH, TITLE } from ".";
import {
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  FileAddOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useSearchParamsTableState from "../../hooks/useSearchParamsTableState";
import service from "../../services/po";
import vendorService from "../../services/vendor";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "throttle-debounce";

const { Title } = Typography;
const MASTER_KEYS = [
  {
    service: vendorService,
    value: "vendors",
  },
];

export default function Entities(props: any) {
  const navigate = useNavigate();
  const [masters, setMasters] = useState<any>({});
  const clientState = useSelector((state: Store) => state.client);
  const [openingRecord, setOpeningRecord] = useState<any>();
  const [cancellingRecord, setCancellingRecord] = useState<any>();
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({
      onFetch: service.paginate,
      defaultFilters: {
        client: [clientState.current],
        status: ["Open"],
      },
    });
  useEffect(() => {
    loadMastersDebounced();
  }, []);
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (vendor) => vendor?.name,
      filters: masters.vendors?.map((v: any) => ({
        text: v.name,
        value: v._id,
      })),
      filterSearch: true,
    },
    {
      title: "Total SKUs",
      dataIndex: "totalSKUs",
      key: "totalSKUs",
    },
    {
      title: "Total Quantity",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
    },
    {
      title: "Total Received",
      dataIndex: "totalReceived",
      key: "totalReceived",
      render: (value, record) => {
        return record.skus
          .map((sku) => sku.quantities)
          .flat()
          .filter((q) => q.received || q.rejected)
          .reduce((acc, val) => val.received + acc, 0);
      },
    },
    {
      title: "Total Pending",
      dataIndex: "totalReceived",
      key: "totalReceived",
      render: (value, record) => {
        return (
          record.totalQuantity -
          record.skus
            .map((sku) => sku.quantities)
            .flat()
            .filter((q) => q.received || q.rejected)
            .reduce((acc, val) => val.received + acc, 0)
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, record) => {
        const tags = [
          { value: "Open", label: <Tag color="cyan">Open</Tag> },
          { value: "Closed", label: <Tag color="green">Closed</Tag> },
          { value: "Cancelled", label: <Tag>Cancelled</Tag> },
        ];
        return tags.find((tag) => tag.value === value)?.label;
      },
    },
    {
      title: "Start At",
      dataIndex: "startAt",
      key: "startAt",
      render: (startAt) => dayjs(startAt).format("DD/MM/YY hh:mm A"),
    },
    {
      title: "Expiry At",
      dataIndex: "expiryAt",
      key: "expiryAt",
      render: (startAt) => dayjs(startAt).format("DD/MM/YY hh:mm A"),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (createdBy) => createdBy?.name,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.status === "Open" && (
            <div
              onClick={() => navigate(`${BASE_PATH}/form/${record._id}`)}
              className="text-blue-500 flex gap-x-2 items-baseline cursor-pointer"
            >
              <EditOutlined /> Edit
            </div>
          )}
          <div
            onClick={() => navigate(`${BASE_PATH}/${record._id}`)}
            className="text-blue-500 flex gap-x-2 items-baseline cursor-pointer"
          >
            <EyeOutlined /> View
          </div>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (tableState) {
      setTableState({
        ...tableState,
        filters: {
          ...tableState.filters,
          client: [clientState.current],
        },
      });
    }
  }, [clientState.current]);

  return (
    <div>
      <Modal
        title="Re-Open Purchase Order"
        open={!!openingRecord}
        onOk={() => {
          if (openingRecord) {
            service
              .update({ ...openingRecord, status: "Open" })
              .then(onRefresh);
            setOpeningRecord(undefined);
          }
        }}
        onCancel={() => setOpeningRecord(undefined)}
      >
        <p>Are you sure you want to re-open this purchase order?</p>
      </Modal>
      <Modal
        title="Cancel Purchase Order"
        open={!!cancellingRecord}
        onOk={() => {
          if (cancellingRecord) {
            service
              .update({ ...cancellingRecord, status: "Cancelled" })
              .then(onRefresh);
            setCancellingRecord(undefined);
          }
        }}
        onCancel={() => setCancellingRecord(undefined)}
      >
        <p>Are you sure you want to cancel this purchase order?</p>
      </Modal>
      <Row justify="space-between">
        <Col>
          <Title level={3}>Open {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <Space>
            <Input.Search
              placeholder="Search"
              onChange={(e) => setInput(e.target.value)}
            />
            <Button
              type="link"
              onClick={() => navigate(`${BASE_PATH}/report`)}
              icon={<FileAddOutlined />}
            >
              Generate Report
            </Button>
            <Button
              type="primary"
              onClick={() => navigate(`${BASE_PATH}/manual-bulk`)}
              icon={<AuditOutlined />}
            />
            <Button
              type="primary"
              onClick={() => navigate(`${BASE_PATH}/form`)}
              icon={<PlusOutlined />}
            >
              Add New
            </Button>
            <Button
              type="primary"
              onClick={() => navigate(`${BASE_PATH}/bulk`)}
              icon={<PlusOutlined />}
            >
              Upload ROAS Report
            </Button>
          </Space>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={data?.list}
            columns={columns}
            pagination={{
              ...(tableState?.pagination || {}),
              total: data?.queryCount,
            }}
            bordered
            onChange={(pagination, filters, sorter) => {
              delete (sorter as any).column;

              setTableState({
                pagination,
                filters: {
                  ...filters,
                  client: [clientState.current],
                  status: ["Open"],
                },
                sorter,
              });
            }}
            rowKey={(record) => record._id}
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
}
