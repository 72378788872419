import {
  Row,
  Col,
  Space,
  Button,
  Form,
  Select,
  DatePicker,
  Table,
  Tag,
  Dropdown,
} from "antd";
import { TITLE, BASE_PATH } from ".";
import Title from "antd/es/typography/Title";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import dayjs from "dayjs";
import service from "../../services/ledger";
import * as poActions from "../../store/poSlice";
import { SyncOutlined, DownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ExcelJS from "exceljs";
type StatementEntry = {
  date: string | "";
  type: string;
  reference: string;
  credit: number;
  debit: number;
  balance: number;
  details: any;
  createdAt?: string;
  link?: string;
  grns?: any[];
};
export default function Entities(props: any) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const poState = useSelector((state: Store) => state.po);
  const [invoices, setInvoices] = useState<any[]>([]);
  const [payments, setPayments] = useState<any[]>([]);
  const [creditNotes, setCreditNotes] = useState<any[]>([]);
  const [debitNotes, setDebitNotes] = useState<any[]>([]);
  const [journals, setJournals] = useState<any[]>([]);
  const [rwPOs, setRWPOs] = useState<any[]>([]);
  const [vendor, setVendor] = useState<any>();
  const [data, setData] = useState<any>();
  const [transactions, setTransactions] = useState<StatementEntry[]>([]);
  const clientState = useSelector((state: Store) => state.client);
  const purchaseWarehouseState = useSelector(
    (state: Store) => state.purchaseWarehouse
  );
  const [entities, setEntities] = useState<any[]>([]);
  const [closingBalance, setClosingBalance] = useState(0);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  const refreshVendors = () => {
    dispatch(poActions.fetchVendors());
  };

  const getInvoices = (grns: any[]) => {
    const vendorChallanMap = new Map();
    for (let grn of grns) {
      if (!vendorChallanMap.has(grn.vendorChallanID)) {
        vendorChallanMap.set(grn.vendorChallanID, {
          vendorChallanID: grn.vendorChallanID,
          vendorChallanDate: grn.vendorChallanDate,
          totalReceivedQuantity: 0,
          totalRejectedQuantity: 0,
          grns: [],
        });
      }
      const vendorChallan = vendorChallanMap.get(grn.vendorChallanID);
      vendorChallan.totalReceivedQuantity += grn.totalReceivedQuantity;
      vendorChallan.totalRejectedQuantity += grn.totalRejectedQuantity;
      vendorChallan.grns.push(grn);
    }
    return Array.from(vendorChallanMap.values());
  };
  const handleGetEntries = async (values: any) => {
    const { vendor: vendorId } = values;
    const data = await service.getEntries({
      ...values,
      warehouse: purchaseWarehouseState.selected,
      vendorId,
      client: clientState.current,
      dateRange: [
        values.dateRange[0].startOf("day").toISOString(),
        values.dateRange[1].endOf("day").toISOString(),
      ],
    });
    setOpeningBalance(data.openingBalance);
    setInvoices(getInvoices(data.grns));
    setJournals(data.journals);
    setPayments(data.payments);
    setCreditNotes(data.creditNotes);
    setDebitNotes(data.debitNotes);
    setRWPOs(data.rwPOs);
    setVendor(data.vendor);
    setData(data);
  };

  function buildStatement(
    openingBalance: number,
    payments: any[],
    invoices: any[],
    rwPOs: any[],
    creditNotes: any[],
    debitNotes: any[],
    journals: any[]
  ): StatementEntry[] {
    const entries = [
      ...payments.map((payment) => ({
        date: payment.date,
        type: "Cr",
        reference: payment.id,
        credit: payment.amount,
        debit: 0,
        details: payment,
        createdAt: payment.createdAt,
        link: `/finance/payments/${payment._id}`,
      })),
      ...creditNotes.map((payment) => ({
        date: payment.date,
        type: "Dr",
        reference: payment.id,
        credit: 0,
        debit: payment.amount,
        details: payment,
        createdAt: payment.createdAt,
        link: `/finance/vendor-credit-note/${payment._id}`,
      })),
      ...debitNotes.map((payment) => ({
        date: payment.date,
        type: "Cr",
        reference: payment.id,
        credit: payment.amount,
        debit: 0,
        details: payment,
        createdAt: payment.createdAt,
        link: `/finance/vendor-debit-note/${payment._id}`,
      })),
      ...journals.map((journal) => ({
        date: journal.date,
        type: journal.type === "Cr" ? "Cr" : "Dr",
        reference: journal.id,
        credit: journal.type === "Cr" ? journal.amount : 0,
        debit: journal.type === "Cr" ? 0 : journal.amount,
        details: journal,
        createdAt: journal.createdAt,
        link: `/finance/journal/${journal._id}`,
      })),
      ...rwPOs.map((journal) => {
        const amount = journal.rawMaterials.reduce((sum, rm) => {
          return sum + rm.quantity * rm.price;
        }, 0);
        return {
          date: journal.date,
          type: journal.vendor?._id === vendor._id ? "Cr" : "Dr",
          reference: journal.id,
          credit: journal.vendor?._id === vendor._id ? amount : 0,
          debit: journal.vendor?._id === vendor._id ? 0 : amount,
          details: journal,
          createdAt: journal.createdAt,
          link: `/raw-material-purchase-orders/${journal._id}`,
        };
      }),
      ...invoices.map((invoice) => {
        const amount = invoice.grns.reduce((sum, grn) => {
          return sum + grn.total;
        }, 0);
        return {
          date: invoice.vendorChallanDate,
          type: "Dr",
          reference: invoice.vendorChallanID,
          debit: amount,
          credit: 0,
          details: invoice,
          createdAt: invoice.createdAt,
          grns: invoice.grns.map((grn) => ({
            ...grn,
            link: `/purchases/purchase-orders/goods-receive-notes/${grn._id}`,
          })),
        };
      }),
    ].sort(
      (a, b) =>
        -new Date(b.date || b.createdAt).getTime() +
        new Date(a.date || a.createdAt).getTime()
    );

    console.log(entries);

    const statement: StatementEntry[] = [];

    let balance = openingBalance;

    for (let entry of entries) {
      balance += -entry.credit + entry.debit;
      statement.push({
        ...entry,
        balance,
      });
    }

    const totalCredit =
      statement.reduce((sum, entry) => sum + entry.credit, 0) +
      (openingBalance < 0 ? -openingBalance : 0);

    const totalDebit =
      statement.reduce((sum, entry) => sum + entry.debit, 0) +
      (openingBalance > 0 ? openingBalance : 0);

    setTotalCredit(totalCredit);
    setTotalDebit(totalDebit);
    const closingBalance = totalDebit - totalCredit;
    setClosingBalance(closingBalance);
    const isClosingBalanceNegative = closingBalance < 0;
    const dateRange = form.getFieldValue("dateRange");

    return [
      {
        date: dateRange[0],
        type: "Opening Balance",
        reference: "Opening Balance",
        credit: openingBalance < 0 ? openingBalance : 0,
        debit: openingBalance > 0 ? openingBalance : 0,
        details: {},
        balance: openingBalance,
      },
      ...statement,
      {
        date: dateRange[1],
        type: "Closing Balance",
        reference: "Closing Balance",
        credit: !isClosingBalanceNegative ? closingBalance : 0,
        debit: isClosingBalanceNegative ? -closingBalance : 0,
        details: {},
        balance: 0,
      },
      {
        date: "",
        type: "",
        reference: "",
        credit: totalCredit + (!isClosingBalanceNegative ? closingBalance : 0),
        debit: totalDebit + (isClosingBalanceNegative ? -closingBalance : 0),
        details: {},
        balance: 0,
      },
    ];
  }

  useEffect(() => {
    setTransactions(
      buildStatement(
        openingBalance,
        payments,
        invoices,
        rwPOs,
        creditNotes,
        debitNotes,
        journals
      )
    );
  }, [data]);

  useEffect(() => {
    setEntities([
      ...poState.vendors.data.map((v) => ({ ...v, type: "Vendor" })),
      ...poState.suppliers.data.map((v) => ({ ...v, type: "Supplier" })),
    ]);
  }, [poState.vendors.data, poState.suppliers.data]);

  const handleDownloadAsExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Ledger");

    worksheet.columns = [
      {
        header: "Date",
        key: "date",
        width: 15,
        alignment: { horizontal: "left" },
      },
      {
        header: "Type",
        key: "type",
        width: 20,
        alignment: { horizontal: "left" },
      },
      {
        header: "Reference",
        key: "reference",
        width: 30,
        alignment: { horizontal: "left" },
      },
      {
        header: "Credit",
        key: "credit",
        width: 15,
        alignment: { horizontal: "right" },
      },
      {
        header: "Debit",
        key: "debit",
        width: 15,
        alignment: { horizontal: "right" },
      },
      {
        header: "Balance",
        key: "balance",
        width: 15,
        alignment: { horizontal: "right" },
      },
    ];

    // Invert header row colors
    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF000000" }, // Dark background
      };
      cell.font = {
        color: { argb: "FFFFFFFF" }, // Light text
        bold: true,
      };
    });

    // Add vendor name and date range to the filename
    const vendorName = vendor ? vendor.name.replace(/\s+/g, "_") : "AllVendors";
    const dateRange = form.getFieldValue("dateRange");
    const startDate = dayjs(dateRange[0]).format("YYYYMMDD");
    const endDate = dayjs(dateRange[1]).format("YYYYMMDD");

    transactions.forEach((entry, index) => {
      const formattedDate =
        index === transactions.length - 1
          ? ""
          : entry.date
          ? dayjs(entry.date).format("DD/MM/YYYY")
          : dayjs(entry.createdAt).format("DD/MM/YYYY"); // Updated line

      const row = worksheet.addRow({
        date: formattedDate,
        // ...existing code...
        type: entry.type,
        reference:
          index === 0 ||
          index === transactions.length - 2 ||
          index === transactions.length - 1
            ? ""
            : entry.reference,
        credit: entry.credit || "",
        debit: entry.debit || "",
        balance: entry.balance || "",
      });

      // Merge "Type" & "Reference" columns for rows where they're merged in the table
      if (
        index === 0 ||
        index === transactions.length - 2 ||
        index === transactions.length - 1
      ) {
        worksheet.mergeCells(`B${row.number}:C${row.number}`);
      }

      // Align Credit, Debit, Balance cells to the right
      row.getCell("D").alignment = { horizontal: "right" };
      row.getCell("E").alignment = { horizontal: "right" };
      row.getCell("F").alignment = { horizontal: "right" };
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `ledger_${vendorName}_${startDate}_to_${endDate}.xlsx`;
    link.click();
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <Space></Space>
        </Col>
      </Row>
      <div className="bg-white rounded-lg px-2 py-3">
        <Form
          form={form}
          initialValues={{ dateRange: [dayjs().subtract(30, "days"), dayjs()] }}
          onFinish={handleGetEntries}
        >
          <div className="flex  gap-x-2">
            <Form.Item
              name="vendor"
              label="Entity"
              rules={[{ required: true, message: "Please select an entity" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "300px" }}
                loading={poState.vendors.loading}
                placeholder="Select a entity"
                allowClear={false}
              >
                {entities.map((vendor) => (
                  <Select.Option key={vendor._id} value={vendor._id}>
                    {vendor.name} <Tag>{vendor.type}</Tag>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              icon={<SyncOutlined />}
              type="link"
              onClick={refreshVendors}
            />
          </div>
          <Form.Item
            name="dateRange"
            label="Date Range"
            rules={[{ required: true, message: "Please select a date range" }]}
          >
            <DatePicker.RangePicker format="DD/MM/YYYY" allowClear={false} />
          </Form.Item>
          <Form.Item className="mt-4" noStyle>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>

              {transactions && transactions.length > 3 && (
                <Button onClick={handleDownloadAsExcel}>
                  Download as Excel
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </div>
      {transactions?.length > 3 && (
        <div className="mt-4 ">
          <Table
            size="small"
            pagination={false}
            dataSource={transactions}
            columns={[
              {
                title: "Date",
                dataIndex: "date",
                key: "date",
                render: (date, record, index) =>
                  index === transactions.length - 1
                    ? ""
                    : date
                    ? dayjs(date).format("DD/MM/YYYY")
                    : dayjs(record.createdAt).format("DD/MM/YYYY"), // Updated line
              },
              {
                title: "Type",
                dataIndex: "type",
                key: "type",
                onCell: (_, index) => ({
                  colSpan:
                    index === 0 ||
                    index === transactions.length - 2 ||
                    index === transactions.length - 1
                      ? 2
                      : 1,
                }),
                render: (type) => (
                  <span
                    className={
                      ["Opening Balance", "Closing Balance"].includes(type)
                        ? "font-semibold"
                        : ""
                    }
                  >
                    {type}
                  </span>
                ),
              },
              {
                title: "Reference",
                dataIndex: "reference",
                key: "reference",
                onCell: (_, index) => ({
                  colSpan:
                    index === 0 ||
                    index === transactions.length - 2 ||
                    index === transactions.length - 1
                      ? 0
                      : 1,
                }),
                render: (ref, record) => {
                  if (record.link) {
                    return (
                      <div
                        onClick={() => window.open(record.link, "_blank")}
                        className="text-blue-500 cursor-pointer"
                      >
                        {ref}
                      </div>
                    );
                  } else if (record.grns) {
                    const items = record.grns.map((grn) => ({
                      key: grn._id,
                      value: grn._id,
                      label: (
                        <div
                          onClick={() =>
                            window.open(
                              `/purchases/purchase-orders/goods-receive-notes/${grn._id}`,
                              "_blank"
                            )
                          }
                          className="text-blue-500 cursor-pointer"
                        >
                          {grn.id}
                        </div>
                      ),
                    }));
                    return (
                      <Dropdown menu={{ items }}>
                        <a onClick={(e) => e.preventDefault()}>
                          <Space>
                            {ref}
                            <DownOutlined />
                          </Space>
                        </a>
                      </Dropdown>
                    );
                  } else {
                    return ref;
                  }
                },
              },
              {
                title: "Credit",
                dataIndex: "credit",
                key: "credit",
                onCell: (_, index) => ({
                  className:
                    index === transactions.length - 2
                      ? "border-t-4 border-double border-gray-400"
                      : index === transactions.length - 1
                      ? "font-semibold border-gray-300 border-t-2"
                      : index === 1
                      ? "border-t-2 border-gray-300"
                      : "",
                }),
                render: (amount) =>
                  amount ? `₹ ${amount.toLocaleString("en-IN")}` : "",
              },
              {
                title: "Debit",
                dataIndex: "debit",
                key: "debit",
                onCell: (_, index) => ({
                  className:
                    index === transactions.length - 2
                      ? "border-t-4 border-double border-gray-400"
                      : index === transactions.length - 1
                      ? "font-semibold border-gray-300 border-t-2"
                      : index === 1
                      ? "border-t-2 border-gray-300"
                      : "",
                }),
                render: (amount) =>
                  amount ? `₹ ${amount.toLocaleString("en-IN")}` : "",
              },
              {
                title: "Balance",
                dataIndex: "balance",
                key: "balance",
                render: (amount) =>
                  amount ? `₹ ${amount.toLocaleString("en-IN")}` : "",
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}
