import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Space,
  TimePicker,
  Tooltip,
  Transfer,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_PATH, TITLE } from ".";
import vendorService from "../../services/vendor";
import { Store } from "../../store";

function EntityForm(props: any) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const poState = useSelector((state: Store) => state.po);
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      if (id) {
        await vendorService.update({ _id: id, ...values });
      } else {
        await vendorService.create(values);
      }
      navigate(BASE_PATH);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");
    if (id) {
      setId(id);
      vendorService.get(id).then((data) => {
        form.setFieldsValue(data);
      });
    }
  }, [location]);

  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        New {TITLE[0]}
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="mt-4"
        initialValues={{ openingBalance: 0 }}
      >
        <div className="flex gap-x-2 ">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input className="w-[350px]" placeholder="Enter Name of Firm" />
          </Form.Item>
          <Form.Item
            name="code"
            label="Code"
            rules={[
              { required: true },
              {
                validator: async (rule, value) => {
                  const isValid = await vendorService.checkCode(value, id);
                  if (!isValid) {
                    throw new Error("Code already exists");
                  }
                },
              },
            ]}
          >
            <Input className="w-[350px]" placeholder="Enter Code of Firm" />
          </Form.Item>
        </div>
        <Form.Item name="address" label="Address">
          <Input.TextArea className="w-[350px]" placeholder="Enter Address" />
        </Form.Item>
        <Form.Item name="contactPerson" label="Contact Person">
          <Input
            className="w-[350px]"
            placeholder="Enter Name of Contact Person"
          />
        </Form.Item>
        <div className="flex gap-x-2 ">
          <Form.Item name="msmeDetails" label="MSME Details">
            <Input placeholder="Enter MSME Details" />
          </Form.Item>
          <Form.Item
            name="gst"
            label="GST"
            rules={[{ len: 15, message: "GSTIN must be 15 characters" }]}
          >
            <Input placeholder="Enter GSTIN" />
          </Form.Item>
        </div>
        <div className="flex gap-x-2 ">
          <Form.Item
            name="email"
            label="Email"
            rules={[{ type: "email", message: "Invalid email" }]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[{ len: 10, message: "Phone number must be 10 digits" }]}
          >
            <Input placeholder="Enter phone" />
          </Form.Item>
          <Form.Item
            name="whatsappDetails"
            label="Whatsapp Details"
            rules={[{ len: 10, message: "Phone number must be 10 digits" }]}
          >
            <Input placeholder="Enter whatsapp phone" />
          </Form.Item>
        </div>

        <Form.Item name={["openingBalance"]} label={`Opening Balance`}>
          <InputNumber style={{ width: "300px" }} prefix="₹" defaultValue={0} />
        </Form.Item>
        <Form.Item
          name="products"
          label="Associated Products"
          valuePropName="targetKeys"
        >
          <Transfer
            dataSource={poState.productsForClient.data.map((p) => ({
              key: p._id,
              title: p._id,
            }))}
            render={(item) => item.title}
            showSearch
            filterOption={(inputValue, option) =>
              option.title.toLowerCase().indexOf(inputValue.toLowerCase()) !==
              -1
            }
            listStyle={{
              width: 250,
              height: 300,
            }}
          />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="link" onClick={() => navigate(BASE_PATH)}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export default EntityForm;
