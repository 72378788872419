import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  message,
} from "antd";
import * as XLSX from "xlsx";
import { useEffect, useRef, useState, useMemo, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import service from "../../services/vendorCreditNote";
import skuService from "../../services/sku";
import { BASE_PATH, TITLE } from ".";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import dayjs from "dayjs";
import * as poActions from "../../store/poSlice";
import * as skuActions from "../../store/skuSlice";

import {
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import usePrintQueue from "../../hooks/usePrintQueue";
import { useThrottleEffect, useUpdateEffect } from "ahooks";

export default function EntityForm(props: any) {
  const [form] = Form.useForm();
  const params = useParams();
  const [printQueue, isAvailable] = usePrintQueue();
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const poState = useSelector((state: Store) => state.po);
  const skuState = useSelector((state: Store) => state.sku);
  const isAddingDetail = Form.useWatch("isAddingDetail", form);
  const [vendorSkus, setVendorSkus] = useState<any[]>([]);
  const skus = Form.useWatch("skus", form);
  const amount = Form.useWatch("amount", form);
  const vendor = Form.useWatch("vendor", form);
  const barcode = Form.useWatch("barcode", form);
  const defaultPrices = useRef({});
  const [hasDispersalError, setHasDispersalError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const addRef = useRef<any>();
  const totalDispersed =
    skus?.reduce(
      (acc, curr) => acc + (curr?.quantity || 0) * (curr?.rate || 0),
      0
    ) || 0;
  useThrottleEffect(
    () => {
      dispatch(skuActions.loadSkusAsync({}));
    },
    [],
    {
      wait: 1000,
    }
  );

  useEffect(() => {
    if (params.id) {
      setId(params.id);
      service.get(params.id).then((res) => {
        form.setFieldsValue({
          ...res,
          vendor: res.vendor._id,
          date: dayjs(res.date),
        });
        setData(res);
      });
    }
  }, [params.id]);

  useEffect(() => {
    if (!vendor) {
      return setVendorSkus([]);
    }
    const vendorObject = poState.vendors.data.find((v) => v._id === vendor);
    const vendorProducts = poState.productsForClient.data.filter((p) =>
      vendorObject?.products.includes(p._id)
    );
    setVendorSkus(vendorProducts.map((p) => p.skus).flat());
  }, [poState.productsForClient, vendor, poState.vendors.data]);

  useEffect(() => {
    const totalDispersed =
      skus?.reduce(
        (acc, curr) => acc + (curr?.quantity || 0) * (curr?.rate || 0),
        0
      ) || 0;
    form.setFieldValue("amount", totalDispersed);
  }, [skus]);

  const handleInsertSku = () => {
    if (!vendorSkus || vendorSkus.length === 0) {
      message.error("No SKUs found for the vendor or Vendor not selected");
      return;
    }

    const sku = vendorSkus.find(
      (s) => s.skuCode === barcode || s.encodedSkuCode === barcode
    );
    if (sku) {
      const skus = form.getFieldValue("skus") || [];
      const nextIndex = skus.length;
      skus.push({ sku: sku._id, quantity: 1, rate: 0 });
      setDefaultPrice(nextIndex, sku._id);
      form.setFieldsValue({ skus, barcode: "" });
    } else {
      form.setFields([{ name: "barcode", errors: ["SKU not found"] }]);
    }
  };

  const setDefaultPrice = async (index, value) => {
    const sku = skuState.skus.find((s) => s._id === value);
    const mapping = await skuService.getMappingForSku(sku.skuCode);
    const price = mapping?.purchasePrice || 0;
    if (price !== 0) {
      const skus = form.getFieldValue("skus");
      skus[index].rate = price;
      form.setFieldsValue({ skus });
      defaultPrices.current[index] = price;
    }
  };
  const skuMap = useMemo(() => {
    const map = {};
    skuState.skus.forEach((sku) => {
      map[sku._id] = sku;
    });
    return map;
  }, [skuState.skus]);

  const getTableData = useMemo(() => {
    const productData = {};
    const allSizes = new Set();

    if (skus && skus.length > 0) {
      skus.forEach((item) => {
        const skuId = item.sku;
        const quantity = item.quantity;

        const sku = skuMap[skuId];

        if (!sku) return;
        const tokens = sku.skuCode.split("_");
        const size = tokens.pop();

        const productCode = tokens.join("_"); // Assuming sku has 'productCode' field

        allSizes.add(size);

        if (!productData[productCode]) {
          productData[productCode] = {};
        }

        if (!productData[productCode][size]) {
          productData[productCode][size] = 0;
        }

        productData[productCode][size] += quantity;
      });
    }

    const sizeColumns = Array.from(allSizes).sort();

    const dataSource = Object.keys(productData).map((productCode, index) => {
      const row: any = {
        key: index,
        productCode,
      };
      sizeColumns.forEach((size: string) => {
        row[size] = productData[productCode][size] || 0;
      });
      return row;
    });

    return { dataSource, sizeColumns };
  }, [skuMap, skus]);

  const handlePrintAll = async () => {
    setPrintLoading(true);
    try {
      // Get the SKUs from the form
      const skusInForm = form.getFieldValue("skus") || [];
      if (skusInForm.length === 0) {
        message.error("No SKUs to print");
        setPrintLoading(false);
        return;
      }

      // Extract SKU IDs from the form
      const skuIds = skusInForm.map((skuItem) => skuItem.sku);

      // Fetch SKU objects from your state or service
      let skuObjects = skuState.skus.filter((sku) => skuIds.includes(sku._id));

      // Fetch any missing SKUs from the server
      const missingSkuIds = skuIds.filter(
        (id) => !skuObjects.some((sku) => sku._id === id)
      );
      if (missingSkuIds.length > 0) {
        const fetchedSkus = await skuService.getByIds(missingSkuIds);
        skuObjects = skuObjects.concat(fetchedSkus);
      }

      // Get the selected vendor
      const vendorId = form.getFieldValue("vendor");
      const vendor = poState.vendors.data.find((v) => v._id === vendorId);
      if (!vendor) {
        message.error("Vendor not found");
        setPrintLoading(false);
        return;
      }

      // Prepare the payload for printing
      const payload = [];
      for (let skuItem of skusInForm) {
        const skuId = skuItem.sku;
        const quantity = skuItem.quantity || 0;
        const skuObject = skuObjects.find((s) => s._id === skuId);

        if (!skuObject) {
          // console.error(`SKU not found for id ${skuId}`);
          continue;
        }
        const tokens = skuObject.skuCode.split("_");
        const size = tokens.pop();

        console.log("SKU Object:", skuObject);

        for (let i = 0; i < quantity; i++) {
          payload.push({
            size: size || "",
            euSize: +size + (skuObject?.skuGenderCode === "male" ? 34 : 33),
            quantity: 1,
            barcodeValue: skuObject.skuCode,
            color: skuObject?.skuColorCode || "",
            vendor: vendor.code,
            skuCode: skuObject?.encodedSkuCode || skuObject?.skuCode || "",
          });
        }
      }

      if (payload.length === 0) {
        message.error("No items to print");
        setPrintLoading(false);
        return;
      }

      // Send the payload to the print queue in batches
      const batchSize = 100;
      const numBatches = Math.ceil(payload.length / batchSize);
      for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
        const batch = payload.slice(
          batchIndex * batchSize,
          (batchIndex + 1) * batchSize
        );
        await printQueue.push(batch);
      }

      message.success("Print job submitted successfully");
    } catch (error) {
      console.error("Error pushing to print queue:", error);
      message.error("Error submitting print job");
    } finally {
      setPrintLoading(false);
    }
  };

  const handleDownloadXLSX = async () => {
    try {
      // Get the SKUs from the form
      const skusInForm = form.getFieldValue("skus") || [];
      if (skusInForm.length === 0) {
        message.error("No SKUs to export");
        return;
      }

      // Extract SKU IDs from the form
      const skuIds = skusInForm.map((skuItem) => skuItem.sku);

      // Fetch SKU objects from your state or service
      let skuObjects = skuState.skus.filter((sku) => skuIds.includes(sku._id));

      // Fetch any missing SKUs from the server
      const missingSkuIds = skuIds.filter(
        (id) => !skuObjects.some((sku) => sku._id === id)
      );
      if (missingSkuIds.length > 0) {
        const fetchedSkus = await skuService.getByIds(missingSkuIds);
        skuObjects = skuObjects.concat(fetchedSkus);
      }

      // Map SKU IDs to SKU Codes
      const skuIdToCodeMap = {};
      skuObjects.forEach((skuObj) => {
        skuIdToCodeMap[skuObj._id] = skuObj.skuCode;
      });

      // Consolidate quantities for the same SKU Code
      const skuCodeQuantityMap = {};
      skusInForm.forEach((skuItem) => {
        const skuId = skuItem.sku;
        const quantity = skuItem.quantity || 0;
        const skuCode = skuIdToCodeMap[skuId];

        if (!skuCode) {
          console.error(`SKU code not found for id ${skuId}`);
          return;
        }

        if (skuCodeQuantityMap[skuCode]) {
          skuCodeQuantityMap[skuCode] += quantity;
        } else {
          skuCodeQuantityMap[skuCode] = quantity;
        }
      });

      // Prepare data for XLSX
      const data = Object.keys(skuCodeQuantityMap).map((skuCode) => {
        const quantity = skuCodeQuantityMap[skuCode];

        // CSV String: SKU Code, SKU Code, 1, Quantity
        const csvString = `${skuCode},${skuCode},1,${quantity}`;

        return {
          "SKU Code": skuCode,
          Quantity: quantity,
          "CSV String": csvString,
        };
      });

      if (data.length === 0) {
        message.error("No valid SKUs to export");
        return;
      }

      // Create a new workbook and worksheet
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "SKUs");

      // Convert to binary string and trigger download
      XLSX.writeFile(workbook, "skus.xlsx");

      message.success("XLSX file downloaded successfully");
    } catch (error) {
      console.error("Error generating XLSX file:", error);
      message.error("Error generating XLSX file");
    }
  };

  const refreshVendors = () => {
    dispatch(poActions.fetchVendors());
    dispatch(poActions.fetchProductsForClient());
    dispatch(skuActions.loadSkusAsync({}));
  };

  return (
    <div>
      <Title level={3}>
        {id ? "Edit" : "Add"} {TITLE[0]}
      </Title>
      <Form
        form={form}
        onFinish={handlePrintAll}
        className="mt-8"
        initialValues={{ date: dayjs() }}
        onFinishFailed={(e) => console.log(e)}
      >
        <div className="flex  gap-x-2">
          <Form.Item name="vendor" label="Vendor">
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "300px" }}
              loading={poState.vendors.loading}
              placeholder="Select a vendor"
              allowClear
              disabled={!!id}
            >
              {poState.vendors.data.map((vendor) => (
                <Select.Option key={vendor._id} value={vendor._id}>
                  {vendor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            icon={<SyncOutlined />}
            type="link"
            onClick={refreshVendors}
          />
        </div>

        <div className="flex gap-x-2">
          <Form.Item name="barcode" label="Barcode">
            <Input
              style={{ width: "300px" }}
              placeholder="Enter Barcode"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                  handleInsertSku();
                }
              }}
            />
          </Form.Item>
          <Button
            icon={<CheckOutlined />}
            type="primary"
            onClick={() => handleInsertSku()}
          />
        </div>
        <div>
          <Form.List name="skus">
            {(fields, { add, remove }, { errors }) => {
              return (
                <div className="p-2 border rounded">
                  <div className="flex justify-between items-center">
                    <span className="font-medium">Add Detail</span>
                    <Button
                      onClick={() => add({ quantity: 1, rate: 0 })}
                      type="primary"
                      size="small"
                      icon={<PlusOutlined />}
                    >
                      Add
                    </Button>
                  </div>
                  <Divider className="mt-1 mb-3" />
                  {fields.map((field, index) => (
                    <div className="flex gap-x-2" key={field.key}>
                      <Form.Item>{index + 1}.</Form.Item>
                      <Form.Item
                        {...field}
                        key={`${field.key}-sku`}
                        name={[field.name, "sku"]}
                        rules={[
                          { required: true, message: "SKU Code Missing" },
                        ]}
                        style={{ width: "20%" }}
                      >
                        <Select
                          variant="filled"
                          placeholder="Select SKU"
                          showSearch
                          optionFilterProp="label"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              add({ quantity: 1, rate: 0 });
                            }
                          }}
                          onChange={async (value) => {
                            await setDefaultPrice(index, value);
                          }}
                          options={vendorSkus.map((sku) => ({
                            label: sku.skuCode,
                            value: sku._id,
                          }))}
                        />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        key={`${field.key}-quantity`}
                        name={[field.name, "quantity"]}
                        rules={[
                          { required: true, message: "Quantity Missing" },
                        ]}
                        style={{ width: "15%" }}
                      >
                        <InputNumber
                          variant="filled"
                          placeholder="Enter Quantity"
                          className="w-full"
                          keyboard={false}
                        />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        key={`${field.key}-rate`}
                        name={[field.name, "rate"]}
                        rules={[{ required: true, message: "Rate Missing" }]}
                        style={{ width: "15%" }}
                      >
                        <InputNumber
                          variant="filled"
                          placeholder="Enter Price"
                          keyboard={false}
                          className="w-full"
                          prefix="₹"
                        />
                      </Form.Item>
                      {defaultPrices.current[index] !==
                        form.getFieldValue("skus")?.[index]?.rate && (
                        <Form.Item
                          key={`${field.key}-total`}
                          style={{ width: "20%" }}
                        >
                          {defaultPrices.current[field.name] && (
                            <Alert
                              type="info"
                              message={`Default Price: ₹${
                                defaultPrices.current[field.name]
                              }`}
                              className="py-1 px-2"
                              action={
                                <div
                                  className="ml-2 text-blue-500 cursor-pointer"
                                  onClick={() => {
                                    const skus = form.getFieldValue("skus");
                                    skus[index].rate =
                                      defaultPrices.current[index];
                                    form.setFieldsValue({ skus });
                                  }}
                                >
                                  Set Default
                                </div>
                              }
                            />
                          )}
                        </Form.Item>
                      )}
                      {form.getFieldValue("skus")?.[index]?.quantity &&
                      form.getFieldValue("skus")?.[index]?.rate !== 0 ? (
                        <Form.Item
                          key={`${field.key}-total`}
                          style={{ width: "10%" }}
                        >
                          ₹
                          {(
                            form.getFieldValue("skus")?.[index]?.quantity *
                            form.getFieldValue("skus")?.[index]?.rate
                          ).toLocaleString()}
                        </Form.Item>
                      ) : undefined}
                      <div>
                        <Button
                          onClick={() => remove(field.name)}
                          icon={<DeleteOutlined />}
                        ></Button>
                      </div>
                    </div>
                  ))}
                </div>
              );
            }}
          </Form.List>
        </div>

        <Form.Item className="mt-4">
          <Space>
            <Button
              type="primary"
              onClick={handlePrintAll}
              disabled={!isAvailable || printLoading}
            >
              {printLoading ? "Printing..." : "Print"}
            </Button>
            <Button onClick={handleDownloadXLSX}>Download XLSX</Button>
            <Button type="link" onClick={() => setIsModalVisible(true)}>
              Show Report
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {/* Modal Component */}
      <Modal
        title="SKU Summary"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={getTableData.dataSource}
          columns={
            [
              {
                title: "Product Code",
                dataIndex: "productCode",
                key: "productCode",
              },
              // Dynamically add size columns
              ...getTableData.sizeColumns.map((size) => ({
                title: size,
                dataIndex: size,
                key: size,
                render: (text) => text || 0,
              })),
            ] as any
          }
          pagination={false}
        />
      </Modal>
    </div>
  );
}
