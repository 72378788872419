import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import service from "../services/rwpo";
import skuService from "../services/sku";
import { FilePdfOutlined } from "@ant-design/icons";
import getDocDefinition from "../utils/getRWPODocDefinition";
import { useSelector } from "react-redux";
import { Store } from "../store";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function RWPurchaseOrderPDFModal(props: any) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [data, setData] = useState<any>();
  const clientState = useSelector((state: Store) => state.client);

  const isButtonDisabled = () => {
    if (!data) return true;
    if (data.status === "Pending Approval") {
      return !props.permissions.approve;
    }
    return false; // Changed from true to false
  };

  const showModal = async () => {
    if (!data) return;

    console.log(data, props.permissions);

    // Define the PDF document
    const docDefinition: any = getDocDefinition(data);

    // Generate the PDF
    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
      setIsModalVisible(true);
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setPdfUrl(null);
  };

  useEffect(() => {
    if (props.id) {
      service.get(props.id).then((res) => {
        const clientData = clientState.clients.find(
          (c) => c._id === res.client
        );
        setData({ ...res, warehouse: clientData?.alias });
        showModal();
      });
    }
  }, [props.id]);

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        icon={<FilePdfOutlined />}
        disabled={isButtonDisabled()}
      >
        Show PDF
      </Button>
      <Modal
        title="PDF Viewer"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        style={{ top: 20 }}
      >
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            style={{ width: "100%", height: "1020px", border: "none" }}
          />
        )}
      </Modal>
    </>
  );
}
